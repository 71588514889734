/* ----------------------------------------------------
 *  17. Tables
 *
 *  This part contains the styles for all the tables
 *  
 *  Contains the over ride CSS for
 *      Boostrap Tables
 *      DataTables
 *      FooTable
 *      Editable
 * ---------------------------------------------------- */


/* ====== Bootstrap Table ===== 
------------------------------------------------------------ */
.table {
    > thead {
        > tr {
            > th {
                font-family: $header-font-family;
                padding: 15px;
                border-bottom-width: 3px;
                border-color: #ddd !important;
                text-transform: uppercase;
            }
        }
    }
    > tbody {
        > tr {
            &:hover {
                background: lighten($info, 52%) !important;
            }

            &:first-child {
                td {
                    border-top: none;
                }
            }

            > td {
                padding: 20px;
                border-top: solid 1px #eee;
            }

            &.active {
                // background: 
            }

            &.primary {
                td {
                    background: $primary;
                }
            }

            &.success {
                td {
                    background: $success;
                }
            }

            &.info {
                td {
                    background: $info;
                }
            }

            &.warning {
                td {
                    background: $warning;
                }
            }

            &.danger {
                td {
                    background: $danger;
                }
            }
        }
    }

    &.table-condensed {
        thead {
            tr {
                th {
                    padding: 8px;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 8px;
                }
            }
        }
    }

    &.table-striped {
        tbody {
            tr:nth-child(odd) {
                background: $white;
            }
        }
    }
}

/* ====== Datatables ===== 
------------------------------------------------------------ */
.dataTables_wrapper {
    margin-bottom: 15px;

    .DTTT_container {
        display: block;
        float: none;
        text-align: right;

        a {
            display: inline-block !important;
            margin: 0;
            border: solid 1px #ddd;
            background: $white;
            padding: 10px 15px;
            box-shadow: none;
            border-radius: 0;

            &:hover {
                background: $white;
                box-shadow: none;
                border-color: #ddd;
            }
        }
    }

    .dataTables_length {
        height: 35px;
        line-height: 35px;
    }

    .dataTables_filter {
        label {
            text-transform: uppercase;

            input {
                box-shadow: none;
                border: 2px solid #e5e5e5;
                padding: 5px;
                height: 35px;
                line-height: 25px;
                color: $font-color;
                background: transparent;
                @include appearance(none);
                @include border-radius(0px);
            }
        }
    }

    .dataTable {
        &.no-footer {
            border-color: #eee;
            margin-bottom: 15px;
        }

        thead {
            th {
                &.sorting_asc,
                &.sorting_desc {
                    color: $black;
                }
            }
        }
    }

    .dataTables_paginate {
        .paginate_button {
            margin: 0;
            border: none;
            opacity: 0.65;
            padding: 0;
            height: 33px;
            line-height: 33px;
            padding: 0 10px;

            &.current {
                background: transparent;
                border: none;
                font-weight: bold;
                opacity: 1;

                &:hover {
                    background: transparent;
                    border: none;
                }
            }

            &:hover,
            &:focus {
                background: transparent;
                box-shadow: none;
                border: none;
                opacity: 1;
                color: $font-color !important;
            }

            &.disabled {
                opacity: 0.65 !important;
            }

            &.previous,
            &.next {
                position: relative;
                color: transparent !important;
                width: 30px;
                margin: 0;
                opacity: 1;

                &:hover {
                    color: transparent !important;
                    background: transparent !important;
                    border: none !important;  
                }
            }

            &.previous {
                &:before {
                    font-family: 'FontAwesome';
                    content: "\f060";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 33px;
                    line-height: 33px;
                    color: $font-color !important;
                    font-size: 0.8em;
                }
            }

            &.next {
                &:before {
                    font-family: 'FontAwesome';
                    content: "\f061";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 33px;
                    line-height: 33px;
                    color: $font-color !important;
                    font-size: 0.8em;
                }
            }
        }
    }
}

.fixedHeader-floating {
    top: 50px !important;
}


/* ====== Footable ===== 
------------------------------------------------------------ */
.table {
    &.footable {
        &.footable-filtering {
            thead {
                tr {
                    &.footable-filtering {
                        th {
                            border-color: transparent !important;
                        }

                        .input-group {
                            height: 45px;

                            .input-group-btn {
                                height: 100%;

                                button {
                                    background: $white;
                                    border-color: #e5e5e5;
                                    border-left-color: transparent;
                                    border-right-color: transparent;
                                    color: $font-color !important;
                                    height: 100%;
                                    border-radius: 0;
                                    height: 45px;

                                    &.dropdown-toggle {
                                        border-right-color: #e5e5e5;
                                    }
                                }
                            }
                        }
                    }

                    &.footable-header {
                        .footable-asc,
                        .footable-desc {
                            color: $black;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                background: $white;

                &:hover {
                    background: lighten($info, 52%);
                }
            }

            tr.footable-detail-row {
                > td {
                    padding: 0 !important;
                    background: #f9f9f9;
                }

                .footable-details {
                    margin-bottom: 0;
                    
                    tr {
                        th,
                            td {
                                padding: 20px;
                                background: #f9f9f9;
                            }

                            th {
                                padding-left: 40px;
                                border-top: solid 1px #eee;
                            }

                            &:first-child {
                                th {
                                    border-top: none;
                                }
                            }
                    }
                }
            }

            .footable-toggle {
                font-size: 16px;
                margin-right: 10px;

                &.fooicon-minus {
                    &:before {
                        font-family: "FontAwesome";
                        content: "\f056";
                    }
                }

                &.fooicon-plus {
                    &:before {
                        font-family: "FontAwesome";
                        content: "\f055";
                    }
                }
            }
        }

        tfoot {
            .footable-paging {
                .label {
                    margin-top: 10px;

                }

                .pagination {
                    li {

                        &.active {
                            a {
                                background: $primary;
                                color: $white;
                                

                                &:hover {
                                    background: $primary;
                                    color: $white;
                                }
                            }
                        }

                        a {
                            background: transparent;
                            color: $font-color;
                            border-radius: 50%;
                            border: none;
                            margin-right: 5px;

                            &:hover {
                                background: transparent;
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
}



/* ====== Editable ===== 
------------------------------------------------------------ */
.editable-container {
    .editableform {
        .editable-input {
            margin-bottom: 15px;

            .well {
                padding: 0;
                border: none;

                .datepicker {
                    padding: 0;
                    background: $white;
                    border: none;
                }
            }

            select {
                margin: 0 5px 5px 0;
            }
        }
        .editable-buttons {
            button {
                margin: 0 15px 15px 0;
            }
        }
    }
}