/* ----------------------------------------------------
 *  12. Masonry
 *
 *  CSS for the placement and style of the masonry
 * ---------------------------------------------------- */



.masonry-grid {
	background: transparent;
	margin-bottom: 15px;

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	.masonry-grid-sizer,
	.masonry-grid-item {
		width: 33.333%;
	}

	.masonry-grid-item {
		float: left;
		padding: 4px;

		img {
			display: block;
			max-width: 100%;
		}
	}
}