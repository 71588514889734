/* ----------------------------------------------------
 * 22. Mail
 *
 * Contains all the styling for web mail
 * ---------------------------------------------------- */
 

 // Content Wrapper
.content-wrapper {
	&.mail {
		#page-content {
			padding: 50px 0 0 0;
			
			.mail-container {
				height: 100%;

				.mail-menu {
					float: left;
					width: 75px;
					height: 100%;
					background: #27333F;

					ul {
						li {
							&.menu-header {
								font-family: $header-font-family;
								text-align: center;
								text-transform: uppercase;
								color: #535F6B;
								margin: 15px 0;
							}

							&.active {
								a {
									color: $white;
								}
							}

							a {
								height: 65px;
								line-height: 65px;
								display: block;
								text-align: center;
								color: #535F6B;
								position: relative;

								.badge {
									position: absolute;
									top: 10px;
									right: 18px;
								}

								i {
									font-size: 24px;
								}

								&:hover,
								&:focus {
									text-decoration: none;
									color: $white;
								}
							}
						}
					}
				}

				.mail-list {
					height: 100%;
					width: 400px;
					overflow: auto;
					position: relative;
					background: #f9f9f9;
					float: left;
					// border-right: solid 1px #ddd;

					.ioslist-fake-header {
						font-family: $header-font-family;
						padding: 3px 15px;
						font-size: 12px;
						font-weight: 400;
						text-transform: uppercase;
						border: solid 1px #ddd;
						border-left: none;
						border-right: none;
						width: 100%;
						text-shadow: none;
						background: #f1f1f1;
						color: rgba(0, 0, 0, 0.5);
						z-index: 900;
					}

					.ioslist-wrapper {
						.ioslist-group-container {
							padding-top: 29px;
							.ioslist-group-header {
								@extend .ioslist-fake-header;
							}

							.mail-item {
								margin: 0;
								cursor: pointer;
								border-bottom: solid 1px #f1f1f1;
								position: relative;

								&.active {
									background: $white;
								}

								&.flag-primary,
								&.flag-success,
								&.flag-info,
								&.flag-warning,
								&.flag-danger {
									&:before {
										content: '';
										position: absolute;
										display: block;
										top: 0;
										right: 0;
										bottom: 0;
										width: 3px;
										height: 100%;
									}
								}

								&.flag-primary {
									&:before {
										background: $primary;
									}
								}

								&.flag-success {
									&:before {
										background: $success;
									}
								}

								&.flag-info {
									&:before {
										background: $info;
									}
								}

								&.flag-warning {
									&:before {
										background: $warning;
									}
								}

								&.flag-danger {
									&:before {
										background: $danger;
									}
								}
		


								&:hover {
									background: lighten($primary, 38%) !important;
								}

								.mail-list-sender {
									color: $black;
								}

								.mail-list-subject {
									color: $black;
								}
							}
						}
					}
				}

				.mail-message {
					overflow: auto;
					height: 100%;
					background: $white;

					.mail-header {
						background: #fff;
					}

					.current-mail-actions {
						background: #fff;
						// border-bottom: solid 1px #ddd;
					}

					.mail-attachments {
						img {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}