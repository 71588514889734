/* ----------------------------------------------------
 *  18. Modals
 *
 *  Contains all the overridden and added CSS for the
 *  modals animations
 * ---------------------------------------------------- */


/* ====== Modals ===== 
------------------------------------------------------------ */
.modal {
	&.fade {

		// Modal Top
		&.modal-top {
			> .modal-dialog {
				margin-top: 0;
				@include translate3d(0, -100%, 0);
			}

			.modal-content {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}

			&.in {
				> .modal-dialog {
					@include translate3d(0, 0, 0);		
				}
			}
		}
	

		// Modal Center
		&.modal-center {
			.modal-dialog {
				@include translate2d(0, 0);
				margin: 0 auto;
				display: table;
				height: 100%;
				-webkit-animation-duration: 0.3s;
				animation-duration: 0.3s;
				-webkit-animation-fill-mode: forwards;
				animation-fill-mode: forwards;
				-webkit-animation-name: modal-center-anim-close;
				animation-name: modal-center-anim-close;

				.modal-content-container {
					display: table-cell;
					vertical-align: middle;
				}

			}

			&.in {
				.modal-dialog {
					-webkit-animation-name: modal-center-anim-open;
					animation-name: modal-center-anim-open;
					-webkit-animation-duration: 0.3s;
					animation-duration: 0.3s;
					-webkit-animation-fill-mode: forwards;
					animation-fill-mode: forwards;
				}			
			}
		}
		

		// Modal Bottom
		&.modal-bottom {
			overflow: hidden;
			@include animation(bottom-modal-anim-close 0.3s ease-in-out);
			-webkit-transform-origin: 50% 100%;
			-moz-transform-origin: 50% 100%;
			-ms-transform-origin: 50% 100%;
			-o-transform-origin: 50% 100%;
			transform-origin: 50% 100%;

			.modal-dialog {
				@include translate2d(0, 0);
				margin: 0 auto;
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
			}

			.modal-content {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			&.in {
				@include animation(bottom-modal-anim-open 0.3s ease-in-out);

				.modal-content {
					> * {
						-webkit-animation: bottom-modal-anim-elem 0.3s both;
						animation: bottom-modal-anim-elem 0.3s both;
					}
					.modal-header {
						-webkit-animation-delay: 0.15s;
						animation-delay: 0.15s;
					}

					.modal-body {
						-webkit-animation-delay: 0.20s;
						animation-delay: 0.20s;
					}

					.modal-footer {
						-webkit-animation-delay: 0.25s;
						animation-delay: 0.25s;
					}
				}
			}
		}
	}
}

/* ====== Dummy Showcase ===== 
------------------------------------------------------------ */
.dummy-modal {
	background: #eee;
	padding: 15px;
	overflow: hidden;
	width: 70%;

	.dummy-modal-header {
		> .dummy-content {
			height: 10px;
			width: 30%;
			background: #aaa;
			margin-bottom: 10px;
		}
	}

	.dummy-modal-body {
		> .dummy-content {
			height: 10px;
			background: #aaa;
			margin-bottom: 5px;

			&:first-child {
				width: 70%
			}

			&:nth-child(2) {
				width: 60%
			}

			&:nth-child(3) {
				width: 80%
			}
		}
	}

	.dummy-modal-footer {
		.dummy-content {
			height: 15px;
			width: 100px;
			margin-right: 10px;
			margin-top: 15px;
			float: left;

			&:first-child {
				width: 30%;
			}

			&:nth-child(2) {
				background: $info;
				width: 20%;
				float: right;
			}

			&:nth-child(3) {
				background: $grey;	
				width: 20%;
				float: right;
			}
		}
	}
}

.dummy-modal-top {
	height: 175px;
	border: dashed 1px;
	border-color: #ddd;
	position: relative;

	> .dummy-modal {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}

.dummy-modal-bottom {
	height: 175px;
	border: dashed 1px;
	border-color: #ddd;
	margin: 0 auto;
	position: relative;

	> .dummy-modal {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

}

.dummy-modal-center {
	height: 175px;
	border: dashed 1px;
	border-color: #ddd;
	margin: 0 auto;
	position: relative;

	> .dummy-modal {
		height: 125px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

}




/* ====== Modal Keyframes - Bottom Modal ===== 
------------------------------------------------------------ */

@-webkit-keyframes modal-center-anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes modal-center-anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); transform: scale3d(0.9, 0.9, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes modal-center-anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); }
}

@keyframes modal-center-anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); transform: scale3d(0.9, 0.9, 1); }
}



/* ====== Modal Keyframes - Bottom Modal ===== 
------------------------------------------------------------ */
@-webkit-keyframes bottom-modal-anim-open {
	0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg); }
	100% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg); }
}

@keyframes bottom-modal-anim-open {
	0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg); transform: perspective(1000px) rotate3d(1,0,0,90deg); }
	100% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg); transform: perspective(1000px) rotate3d(1,0,0,0deg); }
}

@-webkit-keyframes bottom-modal-anim-close {
	0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg); }
	100% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg); }
}

@keyframes bottom-modal-anim-close {
	0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,0deg); transform: perspective(1000px) rotate3d(1,0,0,0deg); }
	100% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg); transform: perspective(1000px) rotate3d(1,0,0,90deg); }
}

/* Inner elements animations */

@-webkit-keyframes bottom-modal-anim-elem {
	0% { opacity: 0; -webkit-transform: translate3d(0, 100px, 0); }
	100% { opacity: 1; -webkit-transform: translate3d(0, 0, 0); }
}

@keyframes bottom-modal-anim-elem {
	0% { opacity: 0; -webkit-transform: translate3d(0, 100px, 0); transform: translate3d(0, 100px, 0); }
	100% { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
}