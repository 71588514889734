/* ----------------------------------------------------
 *  14. Sliders
 *
 *  Contains the over ride CSS for noUISlider and
 *  ionRangeSlider and also contains the extra
 *  CSS for the extra components we have added
 *  like the gradient and contextual sliders.
 * ---------------------------------------------------- */



/* ====== noUi Slider ===== 
------------------------------------------------------------ */
.noUi-target {
    border: none;

    &.noUi-multiple-handle {
        .noUi-base {
            background: #e9e9e9;
            border-radius: 5px;
        }
        .noUi-connect-inverse {
            display: none;
        }
    }

    &.noUi-horizontal {
        height: 10px;

        .noUi-handle {
            top: -8px;
        }
    }

    &.noUi-vertical {
        width: 10px;

        .noUi-handle {
            left: -8px;
        }
    }

    &.noUi-horizontal,
    &.noUi-vertical {
        .noUi-base {
            border-radius: 5px;

            .noUi-connect,
            .noUi-connect-inverse {
                border-radius: 5px;
            }

            .noUi-connect {
                background: $primary;
                box-shadow: none;
                border: none;
            }

            .noUi-connect-inverse {
                background: #e9e9e9;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .noUi-origin {
                .noUi-handle {
                    height: 25px;
                    width: 25px;
                    background: $white;
                    border-radius: 50%;
                    box-shadow: 0 2px 2px #ddd;
                    border: solid 1px #f1f1f1;

                    &:after,
                    &:before {
                        display: none;
                    }
                }
            }
        }

        &.noUi-slider-primary {
            .noUi-connect {
                background: $primary;
            }
        }

        &.noUi-slider-success {
            .noUi-connect {
                background: $success;
            }
        }

        &.noUi-slider-info {
            .noUi-connect {
                background: $info;
            }
        }

        &.noUi-slider-warning {
            .noUi-connect {
                background: $warning;
            }
        }

        &.noUi-slider-danger {
            .noUi-connect {
                background: $danger;
            }
        }

        &.noUi-slider-black {
            .noUi-connect {
                background: $black;
            }
        }

        &.noUi-slider-grey {
            .noUi-connect {
                background: $grey;
            }
        }

        &.noUi-gradient-mini {
            .noUi-base {
                background-color: rgb(255, 130, 53);
                background-image: linear-gradient(to left, rgb(48, 232, 191), rgb(255, 130, 53));

                .noUi-connect {
                    background: transparent;
                }
            }
        }

        &.noUi-gradient-peach {
            .noUi-base {
                background-color: rgb(255, 237, 188);
                background-image: linear-gradient(to left, rgb(237, 66, 100), rgb(255, 237, 188));

                .noUi-connect {
                    background: transparent;
                }
            }
        }

        &.noUi-gradient-virgin {
            .noUi-base {
                background: #C9FFBF;
                background: -webkit-linear-gradient(to left, #C9FFBF , #FFAFBD);
                background: linear-gradient(to left, #C9FFBF , #FFAFBD);

                .noUi-connect {
                    background: transparent;
                }
            }
        }

        &.noUi-gradient-rosewater {
            .noUi-base {
                background: rgb(95, 195, 228);
                background-image: linear-gradient(to left, rgb(229, 93, 135), rgb(95, 195, 228));

                .noUi-connect {
                    background: transparent;
                }
            }
        }

        &.noUi-gradient-almost {
            .noUi-base {
                background: rgb(250, 172, 168);
                background-image: linear-gradient(to left, rgb(221, 214, 243), rgb(250, 172, 168));

                .noUi-connect {
                    background: transparent;
                }
            }
        }

        &.noUi-gradient-vice-city {
            .noUi-base {
                background: rgb(236, 110, 173);
                background-image: linear-gradient(to left, rgb(52, 148, 230), rgb(236, 110, 173));

                .noUi-connect {
                    background: transparent;
                }
            }
        }

        &.noUi-gradient-mild {
            .noUi-base {
                background: rgb(76, 162, 205);
                background-image: linear-gradient(to left, rgb(103, 178, 111), rgb(76, 162, 205));

                .noUi-connect {
                    background: transparent;
                }
            }
        }
    }
}



/* ====== ION Slider ===== 
------------------------------------------------------------ */
.irs {
    .irs-slider {
        &.single {
            background: transparent;
        }

        &.from {
            background: transparent;   
        }

        &.to {
            background: transparent;   
        }
    }

    &.ion-primary {
        .irs {
            .irs-single {
                background: $primary;

                &:after {
                    border-top-color: $primary;
                }
            }

            .irs-from {
                background: $primary;

                &:after {
                    border-top-color: $primary;
                }
            }

            .irs-to {
                background: $primary;

                &:after {
                    border-top-color: $primary;
                }
            }
        }

        .irs-bar {
            background: $primary;
        }
        
        .irs-bar-edge {
            background: $primary;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &.ion-success {
        .irs {
            .irs-single {
                background: $success;

                &:after {
                    border-top-color: $success;
                }
            }

            .irs-from {
                background: $success;

                &:after {
                    border-top-color: $success;
                }
            }

            .irs-to {
                background: $success;

                &:after {
                    border-top-color: $success;
                }
            }
        }

        .irs-bar {
            background: $success;
        }
        
        .irs-bar-edge {
            background: $success;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &.ion-info {
        .irs {
            .irs-single {
                background: $info;

                &:after {
                    border-top-color: $info;
                }
            }

            .irs-from {
                background: $info;

                &:after {
                    border-top-color: $info;
                }
            }

            .irs-to {
                background: $info;

                &:after {
                    border-top-color: $info;
                }
            }
        }

        .irs-bar {
            background: $info;
        }
        
        .irs-bar-edge {
            background: $info;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &.ion-warning {
        .irs {
            .irs-single {
                background: $warning;

                &:after {
                    border-top-color: $warning;
                }
            }

            .irs-from {
                background: $warning;

                &:after {
                    border-top-color: $warning;
                }
            }

            .irs-to {
                background: $warning;

                &:after {
                    border-top-color: $warning;
                }
            }
        }

        .irs-bar {
            background: $warning;
        }
        
        .irs-bar-edge {
            background: $warning;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &.ion-danger {
        .irs {
            .irs-single {
                background: $danger;

                &:after {
                    border-top-color: $danger;
                }
            }

            .irs-from {
                background: $danger;

                &:after {
                    border-top-color: $danger;
                }
            }

            .irs-to {
                background: $danger;

                &:after {
                    border-top-color: $danger;
                }
            }
        }

        .irs-bar {
            background: $danger;
        }
        
        .irs-bar-edge {
            background: $danger;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }
}