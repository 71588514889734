/* ----------------------------------------------------
 *  5. Panels
 *
 *  Only panel which was created for panels for XPLOIT
 * ---------------------------------------------------- */



.panel-x {
 	margin-bottom: 20px;
 	background-color: $white;
	border: 1px solid;
	border-color: rgba(0, 0, 0, 0.07);

	.panel-heading {
		padding: 15px 15px;
		font-family: $header-font-family;
		font-weight: 600;
		text-overflow:ellipsis;
		letter-spacing: 0.02em;
		color: $black;
	}

	&.panel-transparent {
		background: transparent;
		border-color: rgba(0, 0, 0, 0);
	}
}

.panel {
	.panel-heading {
		font-family: $header-font-family;
		font-weight: 600;
		text-overflow:ellipsis;
	}
}