/* ----------------------------------------------------
 * 25. Profile
 *
 * Contains all the styling profile page
 * ---------------------------------------------------- */

.profile-background {
	position: relative;

	.profile-information {
		position: absolute;
		bottom: 0;
		left: 0;

		button {
			&:hover {
				background: $success;
				border-color: $success;
			}
		}
	}

	.profile-stats {
		position: absolute;
		right: 50px;
		bottom: 30px;
	}
}


.profile-content-container {
	padding: 0 25px;

	hr {
		border-top-color: lighten($grey, 20%);
	}

	.write-comment-container {
		#write-comment {
			resize: none;
			border-color: #ddd;
			border-radius: 4px 4px 0 0;
		}

		.write-comment-options {
			background: #f9f9f9;
			padding: 10px 10px;
			// height: 40px;
			border: solid 1px;
			border-top: none;
			border-color: #ddd;
			border-radius: 0 0 4px 4px;
			position: relative;

			i {
				font-size: 16px;
			}

			button {
				position: absolute;
				right: 10px;
				top: 5px;
				bottom: 5px;
				padding: 2px 15px;
				font-size: 13px;
			}
		}
	}

	.profile-content {
		.profile-posts-container {
			.post-comment-container {
				background: #f9f9f9;
				border-radius: 4px;
				overflow: hidden;

				.comments-header {
					background: #eee;
					padding: 10px 15px;
				}

				.media {
					padding: 10px 15px;
					margin: 0;
				}
			}
		}
	}

}