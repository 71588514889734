/* ----------------------------------------------------
 *  19. Widgets
 *
 *  Contains SCSS codes for all the widgets used
 *  in this framework
 * ---------------------------------------------------- */


// Blog Post Widget

.blog-post-min {
	height: 350px;
	margin-bottom: 15px;
	box-shadow: 0 2px 4px #ddd;

	.blog-post-header {
		background: #474652;
		height: 350px;
		color: $white;
		padding: 25px;

		> h4:nth-child(2) {
			position: relative;

			&:after {
				content: '';
				height: 4px;
				width: 35px;
				background: #F5755B;
				position: absolute;
				left: 0;
				bottom: -20px;
				border-radius: 2px;
			}
		}

		.blog-posted-by {
			position: absolute;
			bottom: 15px;
		}
	}

	.blog-post-content {
		padding: 25px;
		background: #fff;
		color: #83848D;
		height: 300px;
	}

	.blog-post-footer {
		height: 50px;
		padding: 0 25px;
		color: #83848D;
		background: $white;
		box-shadow: 0px -10px 10px -10px #f9f9f9;

		p {
			line-height: 50px;
		}

		strong {
			color: #5A5B69;
		}
	}
}

// Blog Post Compact
.blog-post-compact {
	background: $white;
	// height: 350px;
	margin-bottom: 15px;
	box-shadow: 0 2px 4px #ddd;

	.blog-post-header {
		background: #474652;
		padding: 20px;
		height: 100px;
	}

	.blog-post-body {
		padding: 20px;
		// height: 200px;
	}

	.blog-post-footer {
		height: 50px;
		padding: 0 25px;
		color: #83848D;
		background: $white;
		box-shadow: 0px -10px 10px -10px #f9f9f9;

		p {
			line-height: 50px;
		}

		strong {
			color: #5A5B69;
		}
	}
}

// Blog Post Full
.blog-post-full {
	background: $white;
	box-shadow: 0 2px 4px #ddd;
	border-radius: 5px;
	overflow: hidden;

	.blog-post-header {
		height: 300px;
		background-image: url('../images/lightbox/city1.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;

		.media {
			position: absolute;
			bottom: 20px;
			left: 20px;

			h6 {
				color: $white;
			}
		}
	}

	.blog-post-body {
		padding: 20px;
		// height: 200px;
	}

	.blog-post-footer {
		height: 50px;
		padding: 0 25px;
		color: #83848D;
		background: $white;
		box-shadow: 0px -10px 10px -10px #f9f9f9;

		p {
			line-height: 50px;
		}

		strong {
			color: #5A5B69;
		}
	}
}

// Blog Post Slim
.blog-post-slim {
	background: $white;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 2px 4px #ddd;

	.blog-post-header {
		height: 300px;
		background-image: url('../images/lightbox/city1.jpg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.blog-post-body {
		padding: 20px;
	}

	.blog-post-footer {
		height: 50px;
		padding: 0 25px;
		color: #83848D;
		background: $white;
		box-shadow: 0px -10px 10px -10px #f9f9f9;

		p {
			line-height: 50px;
		}

		strong {
			color: #5A5B69;
		}
	}
}



// Mini Stats Container
.mini-stats-container {
	position: relative;
	height: 125px;
	border-radius: 100px;
	margin-bottom: 15px;

	&.mini-stats-pink {
		background: #fe75bd; /* Old browsers */
		background: -moz-linear-gradient(45deg, #fe75bd 0%, #f1d09d 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
     
    &.mini-stats-violet {
    	background: #cb7cff; /* Old browsers */
		background: -moz-linear-gradient(45deg, #cb7cff 0%, #64d4dc 99%, #f1d09d 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #cb7cff 0%,#64d4dc 99%,#f1d09d 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #cb7cff 0%,#64d4dc 99%,#f1d09d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }   

    &.mini-stats-green {
    	background: #72cce1; /* Old browsers */
		background: -moz-linear-gradient(45deg, #72cce1 0%, #f1d09d 99%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #72cce1 0%,#f1d09d 99%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #72cce1 0%,#f1d09d 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .mini-stats-content {
    	height: 125px;
    	text-align: left;
    	padding: 30px 40px;
    }

    .mini-stats-icon {
    	position: absolute;
    	top: 5px;
    	right: 5px;
    	bottom: 5px;
    	background: $white;
    	width: 115px;
    	border-radius: 50%;
    	text-align: center;
    	// display: table;

    	> i {
    		color: $black;
    		margin-top: 25px;
    	}
    }
}

// Easy Pie Chart
.pie-widget {
	text-align: center;
	position: relative;

	.pie-label-container {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		> .pie-label {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

// Mini Calendar
.mini-calendar {
	margin-bottom: 15px;

	.mini-calendar-header {
		background: $white;
		padding: 15px 15px;
		position: relative;
		height: 65px;
		font-family: $header-font-family;
		text-transform: uppercase;

		i {
			position: absolute;
			top: 0;
			bottom:0;
			height: 100%;
			line-height: 65px;
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;

			&.fa-angle-left {
				left: 20px;
			}

			&.fa-angle-right {
				right: 20px;
			}
		}
	}

	.mini-calendar-body {
		background: $danger;
		padding: 20px;
		color: $white;

		ul {
			li {
				display: block;
				position: relative;
				height: 50px;
				line-height: 50px;
				text-transform: uppercase;
				font-size: 14px;
				cursor: pointer;
				border-bottom: solid 1px rgba(0, 0, 0, 0.2);
				padding: 0 10px;

				i {
					position: absolute;
					right: 10px;
					top: 0;
					bottom: 0;
					height: 50px;
					line-height: 50px;
				}
			}
		}

		.mini-calendar-actions {
			padding: 30px 0 0 0;

			i {
				cursor: pointer;
			}
		}
	}
}

// Email Widget
.email-widget {
	margin-bottom: 15px;

	.tab-container {
		ul {

			li {
				display: inline-block;
				width: 33.3333333333%;
				text-align: center;
				background: $white;
				position: relative;

				&.active {
					border-bottom: solid 2px $danger;

					a {
						color: $danger !important;
					}

					&:before {
						bottom: 0;
						left: 0;
						right: 0;
						height: 4px;
						z-index: 990;
						width: 100%;
						background: $danger;
						content: '';
						position: absolute;
					}
				}
			}
		}
	}
}


// User Widget
.mini-user-widget {
	background: $white;
	padding: 7px;
	border-radius: 100px;
	color: $white;
	margin-bottom: 15px;

	&.mini-user-pink {
		background: #fe75bd; /* Old browsers */
		background: -moz-linear-gradient(45deg, #fe75bd 0%, #f1d09d 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
     
    &.mini-user-violet {
    	background: #cb7cff; /* Old browsers */
		background: -moz-linear-gradient(45deg, #cb7cff 0%, #64d4dc 99%, #f1d09d 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #cb7cff 0%,#64d4dc 99%,#f1d09d 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #cb7cff 0%,#64d4dc 99%,#f1d09d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }   

    &.mini-user-green {
    	background: #72cce1; /* Old browsers */
		background: -moz-linear-gradient(45deg, #72cce1 0%, #f1d09d 99%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #72cce1 0%,#f1d09d 99%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #72cce1 0%,#f1d09d 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

	.media {
		.media-left {
			img {
				border-radius: 50%;
				height: 110px;
				width: 110px;
			}
		}

		.media-body {
			h3 {
				margin: 10px 0;
			}
		}
	}
}


// Profile Widget
.profile-widget-min {
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 2px 4px #ddd;
	margin-bottom: 15px;

	.profile-widget-upper {
		background: #fe75bd; /* Old browsers */
		background: -moz-linear-gradient(45deg, #fe75bd 0%, #f1d09d 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */		
		padding: 20px;
		position: relative;

		p {
			color: rgba(255, 255, 255, 0.5);
		}

		.profile-widget-image {
			img {
				border: solid 2px $white;
			}
		}

		.profile-widget-username {
			color: rgba(255, 255, 255, 0.7);	
			font-size: 16px;
		}

		button {
			position: absolute;
			bottom: -21px;
			left: 0;
			right: 0;
			margin: 0 auto;
			box-shadow: 0 1px 2px #ddd;
			color: #fe75bd;
			padding: 10px 15px;
			font-weight: 700;
		}
	}

	.profile-widget-lower {
		background: $white;
		padding: 40px 20px;
		text-align: center;
	}
}


// Connected Social Widget
.connected-social-widget {
	background: $white;
	// height: 85px;
	margin-bottom: 15px;

	.connected-social-profile {
		height: 85px;
		padding: 10px 15px;
		border-bottom: solid 5px $info;
	}

	.connected-social-items {
		height: 85px;
		padding: 10px 15px;
		position: relative;
		cursor: pointer;

		i.social-logo {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 20px;
			height: 85px;
			line-height: 85px;
		}

		.media {
			margin-left: 50px;
			margin-top: 10px;
		}

		&.social-facebook {
			border-bottom: solid 5px $facebook;

			i {
				color: $facebook;
			}

			&.connected {
				background: $facebook;

				i {
					color: $white;
				}

				> .media {
					.media-body {
						> * {
							color: $white;
						}
					}
				}
			}
		}

		&.social-google-plus {
			border-bottom: solid 5px $google-plus;

			i {
				color: $google-plus;
			}
		}

		&.social-twitter {
			border-bottom: solid 5px $twitter;

			i {
				color: $twitter;
			}
		}
	}
}

// Income Chart Widget 
.chart-widget {
	overflow: hidden;
	border-radius: 5px;
	margin-bottom: 15px;

	.highcharts-title {
		font-family: $header-font-family;
		text-transform: uppercase;
		font-size: 14px !important;
	}
}

// Profile Widget Compact
.profile-widget-compact {
	background: $white;
	position: relative;
	margin-top: 64px;
	border-radius: 5px;
	box-shadow: 0 2px 4px #ddd;

	.profile-widget-upper {
		.profile-image {
			position: absolute;
			top: -64px;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;

			img {
				height: 128px;
				width: 128px;
				border-radius: 50%;
			}
		}

		.profile-info {
			padding-top: 75px;
			text-align: center;
		}
	}

	.profile-widget-lower {
		text-align: center;
		background: #F7FBFC;
		padding: 15px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;

		.profile-follow {
			margin-bottom: 10px;

			.row {
				> div {
					&:first-child {
						padding: 15px 15px 15px 70px
					}

					&:last-child {
						padding: 15px 70px 15px 15px;
					}
				}
			}
		}

		.profile-friends {
			margin-bottom: 15px;
			text-align: center;

			ul {
				margin: 0;
				width: 100%;

				li {
					margin-left: -15px;
					padding: 0 !important;

					img {
						height: 48px;
						width: 48px;
						border-radius: 50%;
						border: solid 2px $white;
					}
				}
			}
		}

		// button {
		// 	width: 200px;
		// 	text-transform: uppercase;
		// 	letter-spacing: 1px;
		// }
	}
}

// Profile Widget Simple
.profile-widget-simple {
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 2px 4px #ddd;

	.profile-widget-upper {
		height: 200px;
		background-image: url('../images/lightbox/city1.jpg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.profile-widget-lower {
		background: $white;
		text-align: center;
		padding: 75px 25px 25px 25px;
		position: relative;

		img {
			position: absolute;
			border-radius: 50%;
			height: 128px;
			width: 128px;
			top: -64px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		ul {
			margin: 0;
		}
	}
}