/* ----------------------------------------------------
 *  7. Accordion
 *
 *  This part of the CSS only
 *  contains the over ride CSS for bootstrap tabs
 *  and contains the CSS for extra accordions that are 
 *  available.
 * ---------------------------------------------------- */


/* ============================
    Accordion Default
============================ */
.accordion-default {
    margin-bottom: 20px;

    .panel {
        margin-bottom: 5px;
        background-color: $white;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.07);

        > .panel-heading {
            padding: 0;
            background: $white;
            text-transform: uppercase;
            border-color: transparent;

            > .panel-title {
                font-size: 13px;
                font-weight: 700;

                a {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    padding: 15px 15px 15px 40px;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &.collapsed {
                        &:after {
                            content: "\f055";
                        }
                    }

                    &:after {
                        font-family: "FontAwesome";
                        content: "\f056";
                        position: absolute;
                        left: 15px;
                    }
                }
            }
        }

        &.panel-default {
            > .panel-heading {
                background: $white;
                color: $black;
            }
        }

        &.panel-primary {
            > .panel-heading {
                background: $primary;
                color: $white;
            }
        }

        &.panel-success {
            > .panel-heading {
                background: $success;
                color: $white;
            }
        }

        &.panel-info {
            > .panel-heading {
                background: $info;
                color: $white;
            }
        }

        &.panel-warning {
            > .panel-heading {
                background: $warning;
                color: $white;
            }
        }

        &.panel-danger {
            > .panel-heading {
                background: $danger;
                color: $white;
            }
        }
    }
}

/* ============================
    Accordion Default - Right
============================ */
.accordion-icon-right {
    margin-bottom: 20px;

    .panel {
        margin-bottom: 5px;
        background-color: $white;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.07);

        > .panel-heading {
            padding: 0;
            background: $white;
            text-transform: uppercase;
            border-color: transparent;

            > .panel-title {
                font-size: 13px;
                font-weight: 700;

                a {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    padding: 15px;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &.collapsed {
                        &:after {
                            content: "\f055";
                        }
                    }

                    &:after {
                        font-family: "FontAwesome";
                        content: "\f056";
                        position: absolute;
                        right: 15px;
                    }
                }
            }
        }

        &.panel-default {
            > .panel-heading {
                background: $white;
                color: $black;
            }
        }

        &.panel-primary {
            > .panel-heading {
                background: $primary;
                color: $white;
            }
        }

        &.panel-success {
            > .panel-heading {
                background: $success;
                color: $white;
            }
        }

        &.panel-info {
            > .panel-heading {
                background: $info;
                color: $white;
            }
        }

        &.panel-warning {
            > .panel-heading {
                background: $warning;
                color: $white;
            }
        }

        &.panel-danger {
            > .panel-heading {
                background: $danger;
                color: $white;
            }
        }
    }
}

/* ============================
    Accordion Sticked
============================ */
.accordion-sticked {
    margin-bottom: 20px;

    .panel {
        margin-bottom: 0px;
        background-color: $white;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.07);

        > .panel-heading {
            padding: 0;
            background: $white;
            text-transform: uppercase;
            border-color: transparent;

            > .panel-title {
                font-size: 13px;
                font-weight: 700;

                a {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    padding: 15px;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &.collapsed {
                        &:after {
                            content: "\f055";
                        }
                    }

                    &:after {
                        font-family: "FontAwesome";
                        content: "\f056";
                        position: absolute;
                        right: 15px;
                    }
                }
            }
        }

        &.panel-default {
            > .panel-heading {
                background: $white;
                color: $black;
            }
        }

        &.panel-primary {
            > .panel-heading {
                background: $primary;
                color: $white;
            }
        }

        &.panel-success {
            > .panel-heading {
                background: $success;
                color: $white;
            }
        }

        &.panel-info {
            > .panel-heading {
                background: $info;
                color: $white;
            }
        }

        &.panel-warning {
            > .panel-heading {
                background: $warning;
                color: $white;
            }
        }

        &.panel-danger {
            > .panel-heading {
                background: $danger;
                color: $white;
            }
        }
    }
}

/* ============================
    Accordion Centered Title
============================ */
.accordion-centered-title {
    margin-bottom: 20px;
    
    .panel {
        margin-bottom: 0px;
        background-color: $white;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.07);

        > .panel-heading {
            padding: 0;
            background: $white;
            text-align: center;
            text-transform: uppercase;
            border-color: transparent;

            > .panel-title {
                font-size: 13px;
                font-weight: 700;

                a {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    padding: 15px;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &.collapsed {
                        &:after {
                            content: "\f055";
                        }
                    }

                    &:after {
                        font-family: "FontAwesome";
                        content: "\f056";
                        position: absolute;
                        left: 15px;
                    }
                }
            }
        }

        &.panel-default {
            > .panel-heading {
                background: $white;
                color: $black;
            }
        }

        &.panel-primary {
            > .panel-heading {
                background: $primary;
                color: $white;
            }
        }

        &.panel-success {
            > .panel-heading {
                background: $success;
                color: $white;
            }
        }

        &.panel-info {
            > .panel-heading {
                background: $info;
                color: $white;
            }
        }

        &.panel-warning {
            > .panel-heading {
                background: $warning;
                color: $white;
            }
        }

        &.panel-danger {
            > .panel-heading {
                background: $danger;
                color: $white;
            }
        }
    }
}

/* ============================
    Accordion Colored Icon
============================ */
.accordion-colored-icon {
    margin-bottom: 20px;
    
    .panel {
        margin-bottom: 0px;
        background-color: $white;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.07);

        > .panel-heading {
            padding: 0;
            background: $white;
            text-align: left;
            text-transform: uppercase;
            border: none;

            > .panel-title {
                font-size: 13px;
                font-weight: 700;

                a {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    color: $black;
                    padding: 15px;
                    border-bottom: solid 1px #ddd;
                    padding-left: 70px;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &.collapsed {
                        &:after {
                            content: "\f055";
                        }
                    }

                    &:after {
                        font-family: "FontAwesome";
                        content: "\f056";
                        position: absolute;
                        left: 0px;
                        top: 0;
                        bottom: -1px;
                        font-size: 16px;
                        background: $grey;
                        color: $white;
                        padding: 15px 20px;
                    }
                }
            }
        }

        &.panel-default {
            > .panel-heading {
                a {
                    &:after {
                        background: $grey;
                    }
                }
            }
        }

        &.panel-primary {
            > .panel-heading {
                a {
                    &:after {
                        background: $primary;
                    }
                }
            }
        }

        &.panel-success {
            > .panel-heading {
                a {
                    &:after {
                        background: $success;
                    }
                }
            }
        }

        &.panel-info {
            > .panel-heading {
                a {
                    &:after {
                        background: $info;
                    }
                }
            }
        }

        &.panel-warning {
            > .panel-heading {
                a {
                    &:after {
                        background: $warning;
                    }
                }
            }
        }

        &.panel-danger {
            > .panel-heading {
                a {
                    &:after {
                        background: $danger;
                    }
                }
            }
        }
    }
}

/* ============================
    Accordion Transparent
============================ */
.accordion-transparent {
    .panel {
        background: transparent;
        border-color: transparent;
        box-shadow: none;

        .panel-heading {
            background: transparent;
            border-color: transparent;
        }
    }
}