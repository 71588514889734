// ----------------------------------------------------
//  *  Variables
//  *
//  *  Contains all the variables that are used in other
//  *  SCSS files
//  * ----------------------------------------------------


@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600,700);
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800);


// ====== Typography ===== 
// ------------------------------------------------------------
$font-family:			'Open Sans', 'Helvetica Neue', sans-serif !default;
$font-size:				12px !default;
// $font-color:			#333 !default;
$font-color:			#6a6c6f !default;
$header-font-family:	'Montserrat', sans-serif !default;
$header-font-color:		#232323 !default;

// ====== Font Sizing ====== 
// ------------------------------------------------------------------------------
$font-size-xl:			$font-size * 1.75 !default;
$font-size-lg:			$font-size * 1.25 !default;
$font-size-md:			$font-size !default;
$font-size-sm:			$font-size * 0.85 !default;
$font-size-xs:			$font-size * 0.75 !default;

// ====== Font Weight ====== 
// ------------------------------------------------------------------------------
$fw-lgt:				300 !default;
$fw-nml:				400 !default;
$fw-thk:				700 !default;
$fw-thkr:				800 !default;

// ====== Base Colors ====== 
// ------------------------------------------------------------------------------
$primary:				#7266ba !default;
$success:				#27c24c !default;
$info:					#00abe8 !default;
$warning:				#ff9c00 !default;
$danger:				#f93926 !default;

$white:					#ffffff !default;
$grey:					#adadad !default;
$black:					#0b1115 !default;

$primary-dark:			darken($primary, 10%) !default;
$success-dark:			darken($success, 10%) !default;
$info-dark:				darken($info, 5%) !default;
$warning-dark:			darken($warning, 10%) !default;
$danger-dark:			darken($danger, 10%) !default;
$grey-dark:				darken($grey, 10%) !default;

$primary-light:			lighten($primary, 10%) !default;
$success-light:			lighten($success, 10%) !default;
$info-light:			lighten($info, 10%) !default;
$warning-light:			lighten($warning, 10%) !default;
$danger-light:			lighten($danger, 10%) !default;
$grey-light:			darken($grey, 10%) !default;

// ====== Brand Colors ====== 
// ------------------------------------------------------------------------------
$facebook:				#3b5998 !default;
$twitter:				#00aced !default;
$google-plus:			#dd4b39 !default;
$youtube:				#bb0000 !default;
$linkedin:				#007bb6 !default;
$instagram:				#517fa4 !default;
$pintrest:				#cb2027 !default;
$vine:					#00bf8f !default;
$tumblr:				#35465c !default;
$dribbble:				#ea4c89 !default;
$dropbox:				#007ee5 !default;

// ====== Scaffolding ====== 
// ------------------------------------------------------------------------------
$body-bg:				#f5f5f5 !default;
// $sidebar-bg:			#252d31 !default;
$sidebar-bg:			#151515 !default;
// $sidebar-bg:			#32404e !default;
$header-bg:				#ffffff !default;