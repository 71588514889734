/* ----------------------------------------------------
 *  21. Login, Resgister, Forgot Password, Lockscreen
 *
 *  Styling file for all the above mentioned pages
 * ---------------------------------------------------- */


/*
 *  Lock Screen
 * ========================================================= */

.lockscreen-container {
	width: 400px;
    margin: auto;
    padding: 50px 20px 20px 20px;
	
	.lockscreen {
		border-radius: 5px;
		box-shadow: 0 2px 4px #ddd;
	}
}


/*
 *  Forgot Password
 * ========================================================= */

.fp-container {
    padding: 100px 25px !important;
}

/*
 *  Generic for all the above mentioned pages
 * ========================================================= */

.register-container,
.login-container,
.fp-container {
    width: 500px;
    padding: 50px 25px;
    margin: 0 auto;

    .social-sign-up {
    	position: relative;

    	&:before {
    		content: '';
    		display: block;
    		right: 0;
    		position: absolute;
    		// top: 0;
    		left: 0;
    		bottom: -26px;
    		height: 1px;
    		background: #ddd;
    		z-index: -1;
    		width: 100%;
    	}

    	i {
    		font-size: 2em !important;
    	}

    	+ p {
	    	small {
	    		background: #f1f1f1;
	    		padding: 0 15px;
	    	}
	    }
    }
}

/*
 *  Errors
 * ========================================================= */

.error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}