/* ----------------------------------------------------
 * 23. Todo
 *
 * Contains all the styling for Todo app
 * ---------------------------------------------------- */
 
 .todo-container {
 	height: 650px;
 	max-height: 1000px;

 	.todo-body {
 		overflow: hidden;
 		height: 100%;

		.todo-aside {
			background: #fcfcfc;
			float: left;
			width: 300px;
			height: calc(100% - 50px);

	 		.todo-projects {
				> p {
					padding: 20px 30px;
					margin: 0;
				}

				#todo-projects-ul {
					list-style-type: none;
					margin: 0;
					padding: 0 10px;

					> li {
						height: 40px;
						line-height: 40px;
						position: relative;
						cursor: pointer;
						padding: 0 30px;

						&:before {
							content: '';
							display: block;
							position: absolute;
							top: 15px;
							left: 10px;
							height: 10px;
							width: 10px;
							background: #ccc;
							@include border-radius(50%);
						}

						&:last-child {
							&:before {
								content: none;
							}
						}

						> a.move-project {
						float: left;
						opacity: 0;
						cursor: move;
						margin-right: 10px;
						}

						> .dropdown {
							float: right;
							opacity: 0;
						}

						&:hover {
							> a {
								opacity: 1;
							}

							> .dropdown {
								opacity: 1;
							}
						}
					}
				}
	 		}
	 	}

	 	.todo-project-content {
	 		padding: 20px;
	 		overflow: auto;
	 		height: 100%;

	 		input[name="hiddenField"] {
	 			height: 30px;
	 		}

	 		.todo-task-container {
	 			.todo-content-container {
					display: none;

					&:first-child {
						display: block;
					}
	 			}

	 			.todo-task {
	 				height: 50px;
	 				line-height: 50px;
	 				padding: 0 15px;
	 				cursor: pointer;

	 				> .move-task {
	 					float: left;
	 					opacity: 0;
	 				}

	 				> .checkbox {
	 					margin: 0;
	 					margin-right: 5px;
	 					float: left;
	 				}

	 				> .todo-task-name {
	 					cursor: auto;
	 				}

	 				> .dropdown {
	 					float: right;
	 					opacity: 0;
	 				}

	 				> input {
	 					height: 40px;
	 					line-height: 40px;
	 					width: 80%;
	 					padding: 10px;
	 					margin-top: 5px;
	 					border: 2px solid #e5e5e5;
	 					border-radius: 50px;
	 					// margin-left: 10px;
	 				}

	 				&:hover {
						> a {
							opacity: 1;
						}

						> .dropdown {
							opacity: 1;
						}
	 				}
	 			}
	 		}
	 	}
 	}
 }