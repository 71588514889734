/* ----------------------------------------------------
 *  9. Pricing Table
 *
 *  Contains all the CSS codes for all the pricing tables
 *  shown in the demo
 * ---------------------------------------------------- */

.pricing-table-default {
    background: $white;
    box-shadow: 0 1px 2px #ddd;
    overflow: hidden;
    margin-bottom: 15px;
    @include border-radius(4px);
    @include transition(transform 0.3s);

    &:hover {
        @include transform(scale(1.03));
    }

    .plan-name {
        padding-top: 25px;
        color: $white !important;

        .plan-icon {
            padding: 15px 0;
        }

        .plan-price {
            padding: 10px 0;
        }
    }

    .plan-description {
        > p {
            padding: 10px 10px;
            margin: 0;

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    .order-now {
        padding: 10px 0;
        
        a {
            display: block;
            text-decoration: none;
            color: $white;
            font-size: 14px;

            &:hover {
                text-decoration: none;
                color: $white;
            }
        }
    }

    &.plan-basic {
        .plan-name {
            background: $danger;

            .plan-price {
                background: darken($danger, 7%);
            }

        }

        .order-now {
            background: $danger;
        }
    }

    &.plan-express {
        .plan-name {
            background: $warning;

            .plan-price {
                background: darken($warning, 7%);
            }

        }

        .order-now {
            background: $warning;
        }
    }

    &.plan-business {
        .plan-name {
            background: $info;

            .plan-price {
                background: darken($info, 7%);
            }

        }

        .order-now {
            background: $info;
        }
    }

    &.plan-platinum {
        .plan-name {
            background: $success;

            .plan-price {
                background: darken($success, 7%);
            }
        }

        .order-now {
            background: $success;
        }
    }
}


.pricing-table-border {
    position: relative;
    padding: 25px 15px;
    color: $font-color;
    border: solid 2px;
    border-color: transparent;
    background-color: transparent;
    @include transition(color 0.3s);
    @include transition(background 0.6s);
    border-radius: 4px;

    .plan-name {
        .plan-icon {
            
        }

        .plan-price {
            margin: 25px 0 20px 0;

            > strong {
                font-size: 48px;
            }

            > sup {
                font-size: 32px;
            }
        }
    }

    .plan-description {
        // padding: 5px 0;

        p {
            padding: 10px 15px;
        }
    }

    .order-now {
        padding: 10px 0;

        button {
            background: $primary;
            display: block;
            width: 100%;
            border-radius: 0;
            padding: 10px 15px;
            border: none;
            opacity: 0;
            @include translate3d(0, -15px, 0);
            @include transition(opacity 0.3s);
            @include transition(transform 0.3s);
        }
    }

    &:hover {
        background-color: $white;
        box-shadow: 0 4px 6px #ddd;
        
        // border-color: lighten($primary, 10%);

        .plan-name {

            i {
                color: $primary;
            }

            .plan-price {
                color: $black;
            }
        }

        .order-now {
            button {
                opacity: 1;
                @include translate3d(0, 0, 0);
            }
        }
    }
}

.pricing-table-gradient {
    padding: 35px 35px;
    background: $white;
    box-shadow: 0 4px 6px #ddd;
    border-radius: 4px;

    .plan-name {

        .plan-icon {
            i {
                background: #eee;
                // padding: 10px;
                height: 100px;
                width: 100px;
                line-height: 100px;
                border-radius: 50%;
            }
        }

        .plan-price {
            font-size: 48px;
            line-height: 50px;
            margin: 10px 0;

            strong {
                color: $black;
            }

            sup {
                font-size: 16px;
                top: -25px;
                color: $black;
            }
        }
    }

    .plan-description {
        p {
            padding: 10px 15px;
            font-size: 12px;
            color: #aaa;
            text-transform: uppercase;
        }
    }

    .plan-buy {
        margin-top: 25px;

        button {
            text-transform: uppercase;
            border: none;
            display: block;
            width: 100%;
            padding: 15px;
            color: $white;
            background: #fe75bd; /* Old browsers */
            background: -moz-linear-gradient(45deg, #fe75bd 0%, #f1d09d 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(45deg, #fe75bd 0%,#f1d09d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            i {
                font-size: 14px;
                margin-right: 5px;
            }
        }
    }
}