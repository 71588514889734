/* ----------------------------------------------------
 *  2. Reset
 *
 *  This part contains overridden CSS.
 *  
 *  Description: For all the plugins like Bootstrap,
 *                  lightGallery, jQuery Steps. This
 *                  file has the CSS overridden.
 * ---------------------------------------------------- */


/* ====== Scaffolding ===== 
------------------------------------------------------------ */
* {
	outline: 0 !important;
}

html,
body {
    height: 100%;
	width: 100%;
}

body {
	font-family: $font-family;
	font-size: $font-size;
    color: $font-color;
	overflow-x: hidden;
	background: transparent;
    -webkit-font-smoothing: antialised;
}

/* ====== Typography ===== 
------------------------------------------------------------ */
h1, h2, h3, h4, h5, h6 {
    color: $header-font-color;
    -webkit-font-smoothing: antialised;
}

p {
    font-size: 13px;
    display: block;
    font-weight: normal;
    letter-spacing: 0.01em;
    white-space: normal;
    line-height: 20px;
}

p.header {
    font-family: $header-font-family;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.03em;
    color: $black;
}

/* ====== Breadcrumb ===== 
------------------------------------------------------------ */
.page-title {
    padding: 0 15px;
}

.breadcrumb {
    padding: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    background-color: transparent;

    > li {
        cursor: pointer;
    }

    > li + li:before {
        display: inline-block;
        cursor: default;
        padding: 0;
        font-family: "FontAwesome";
        color: rgba(0,0,0,.7);
        content: "\f105";
        width: 20px;
        text-align: center;
    }
}

/* ====== Morris Tooltip ===== 
------------------------------------------------------------ */
.morris-hover {

    &.morris-default-style {
        border: none;
        box-shadow: none;
        background: $black;
        color: $white;
        font-family: $font-family;
        border-radius: 4px;
        text-align: left;
        
        .morris-hover-row-label {
            font-weight: normal;
        }

        .morris-hover-point {
            color: #f3f3f3 !important;
            font-weight: 300;
        }
    }
}

/* ====== Buttons ===== 
------------------------------------------------------------ */
button:focus {
    outline: none!important;
}

/* ============================
    Override Default Styles
============================ */
.btn {
    font-weight: 300;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 3px;
    background-image: none !important;
    background-color: #ffffff;
    color: $font-color;
    text-shadow: none;
    box-shadow: none;
    @include transition(color 0.2s linear 0s);
    @include transition(background-color 0.2s linear 0s);

    &:hover {
        background-color: #fafafa;
        border: 1px solid rgba(98,98,98,0.27);
    }

    &.active {
        border-color:#e6e6e6;
        background:#fff;
    }

    &:focus,
    &:active:focus,
    &.active:focus {
        outline: none!important;
        outline-style: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        box-shadow: none;
    }
}

/* ============================
    Buttons - Default
============================ */
.btn-default,
.btn-default:focus {
    color: #5e5e5e;
    background-color: #ffffff;
    border-color: #f0f0f0;
}

.btn-default.active,
.btn-default:active,
.btn-default.active:focus,
.btn-default:active:focus,
.btn-default:active:hover {
    background-color: #f0f0f0;
    border-color: #e6e6e6;
    color: #2c2c2c;
}

.btn-default.hover,
.btn-default:hover {
    background-color: #fafafa;
    border-color: rgba(98, 98, 98, 0.27);
    color: #1a1a1a;
}

.btn-default.active:hover {
    background: #f0f0f0;
}

/* ============================
    Rounded Buttons
============================ */
.btn-rounded {
    @include border-radius(50px);
}

/* ============================
    Circle Buttons
============================ */
.btn-circle {
    height: 50px;
    width: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 16px;
    @include border-radius(50%);   
}

/* ============================
    Buttons Variant
============================ */
@include btn-variant(primary, $primary);
@include btn-variant(success, $success);
@include btn-variant(info, $info);
@include btn-variant(warning, $warning);
@include btn-variant(danger, $danger);
@include btn-variant(facebook, $facebook);
@include btn-variant(google-plus, $google-plus);
@include btn-variant(twitter, $twitter);

/* ============================
    Transparent Buttons Variant
============================ */
@include btn-transparent-variant(primary, $primary);
@include btn-transparent-variant(success, $success);
@include btn-transparent-variant(info, $info);
@include btn-transparent-variant(warning, $warning);
@include btn-transparent-variant(danger, $danger);
@include btn-transparent-variant(white, $white);
@include btn-transparent-variant(black, $black);
@include btn-transparent-variant(grey, $grey);

/* ====== Dropdown ===== 
------------------------------------------------------------ */
.btn-group {
    
    /* ============================
        Adding Transition to Dropdown
    ============================ */
    > .dropdown-menu {
        @include transform-origin(top);
        @include animation-fill-mode(forwards);
        @include scale2d(1, 0);
        @include transition(all 0.2s ease-out);
        display: block;
    }

    &.dropup {

        > .dropdown-menu {
            @include transform-origin(bottom);
        }
    }

    &.open {

        > .dropdown-toggle {
            box-shadow: none;
        }

        > .dropdown-menu {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        
        /* ============================
            Dropdown - Default
        ============================ */
        .btn-default,
        .btn-default:focus {
            color: #5e5e5e;
            background-color: #f0f0f0;
            border-color:rgba(98, 98, 98, 0.27);
        }
        
        /* ============================
            Dropdown - Primary
        ============================ */
        .btn-primary,
        .btn-primary:focus {
            color: $white;
            background-color: darken($primary, 12%);
            border-color: darken($primary, 12%);
        }
    
        /* ============================
            Dropdown - Success
        ============================ */
        .btn-success,
        .btn-success:focus {
            color: $white;
            background-color: darken($success, 12%);
            border-color: darken($success, 12%);
        }
        
        /* ============================
            Dropdown - Info
        ============================ */
        .btn-info,
        .btn-info:focus {
            color: $white;
            background-color: darken($info, 12%);
            border-color: darken($info, 12%);
        }
        
        /* ============================
            Dropdown - Warning
        ============================ */
        .btn-warning,
        .btn-warning:focus {
            color: $white;
            background-color: darken($warning, 12%);
            border-color: darken($warning, 12%);
        }
    
        /* ============================
            Dropdown - Danger
        ============================ */
        .btn-danger,
        .btn-danger:focus {
            color: $white;
            background-color: darken($danger, 12%);
            border-color: darken($danger, 12%);
        }
    }
    

    /* ============================
        Override Default Styles
    ============================ */
    .dropdown-menu {
        padding: 0;
        margin: 0;
        border-color: rgba(0, 0, 0, 0.07);
        box-shadow: 0 1px 0px #ddd;
        background-clip: initial;
        @include border-radius(3px);

        > li {

            > a {
                padding: 10px 10px;
                color: #757474;
                @include transition(color 0.2s ease-in);

                &:hover {
                    color: $font-color;
                    background: transparent;
                }
            }
        }
    }
}

.dropdown {
    .dropdown-menu {
        padding: 0;
        margin: 0;
        border-color: rgba(0, 0, 0, 0.07);
        box-shadow: 0 1px 0px #ddd;
        background-clip: initial;
        @include border-radius(3px);

        > li {

            > a {
                padding: 10px 10px;
                color: #757474;
                @include transition(color 0.2s ease-in);

                &:hover {
                    color: $font-color;
                    background: transparent;
                }
            }
        }
    }
}

/* ====== Form Group ===== 
------------------------------------------------------------ */
.form-group {
    label {
        color: #626262;
        font-family: $header-font-family;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.03em;
    }

    .input-group {
        .input-group-addon {
            border-width: 2px;
            border-color: #e5e5e5;
            background: #e5e5e5;
            @include border-radius(0);
        }
    }
}
.form-control {
    box-shadow: none;
    border: 1px solid #e5e5e5;
    padding: 10px 15px;
    height: 45px;
    line-height: 25px;
    color: $font-color;
    background: white;
    @include appearance(none);
    @include border-radius(0px);

    &.input-lg {
        height: 55px;
        line-height: 35px;
    }

    &.input-sm {
        height: 35px;
        padding: 5px 10px;
        line-height: 25px;
    }

    &:focus {
        box-shadow: none;
        border-color: #ababab;
    }

    @include placeholder(#aaa);
}

select.input-sm {
    line-height: 20px !important;
}

.form-inline {
    .checkbox-default {
        > span {
            margin-left: 25px;
        }
    }
    .form-group {
        margin-right: 15px;

        label {
            margin-right: 5px;
        }
    }
}

/* ====== Owl Carousel ===== 
------------------------------------------------------------ */
.owl-theme {
    .owl-nav {
        [class*='owl-'] {
            background: $white;
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-sm;
            color: $font-color;

            &:hover {
                background: $white;
                color: lighten($font-color, 25%);
            }
        }

        .owl-prev {
            &:before {
                font-family: 'FontAwesome';
                content: "\f104";
                font-size: 14px;
                margin-right: 5px;
            }
        }

        .owl-next {
            &:after {
                font-family: 'FontAwesome';
                content: "\f105";
                font-size: 14px;
                margin-left: 5px;
            }
        }
    }

    .owl-dots {
        .owl-dot {
            &.active {
                span {
                    background: $font-color;
                }
            }
        }
    }
}

/* ====== Bootstrap Editable ===== 
------------------------------------------------------------ */
.datepicker {
    &.datepicker-inline {
        width: auto !important;
    }
}

/* ====== Modals ===== 
------------------------------------------------------------ */
.modal {
    .modal-dialog {
        .modal-content {
            box-shadow: none;
            border-radius: 5px;
            border: none;
            padding: 0;

            .modal-header {
                border: none;
                padding: 30px 30px 0 30px;
            }

            .modal-body {
                padding: 0 30px 30px 30px;
            }
        }
    }
}
.modal-backdrop {
    background: rgba(55, 58, 71, 0.9);
    opacity: 0;

    &.in {
        opacity: 1;
    }
}

/* ====== NVD3 CSS Reset ===== 
------------------------------------------------------------ */
.nvd3 text,
.nvd3 .title,
.nvd3.nv-bullet,
.nvtooltip {
    font-family: $font-family;
}

/* ====== Date Picker ===== 
------------------------------------------------------------ */
.input-daterange {
    input {
        text-align: left;
    }
}
.datepicker {
    padding: 15px 20px;
    margin: 0;
    border-color: #f9f9f9;

    &:before {
        border-bottom-color: #f9f9f9;
    }

    .datepicker-days {
        table {
            thead {
                .datepicker-switch {
                    font-weight: bold;
                    font-size: 12px;
                    // text-transform: uppercase;
                }

                .dow {
                    font-family: $header-font-family;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 11px;
                    color: $primary;
                }
            }

            tbody {
                tr {
                    font-size: 12px;
                    td {
                        padding: 7px 10px;
                    }
                }

                .day {
                    &.old,
                    &.new {
                        color: lighten($grey, 10%);
                    }

                    &.active {
                        background: $primary;
                        border-radius: 50%;
                    }

                    &.range-start,
                    &.range-end {
                        &.selected {
                            background: $grey;
                        }

                        &.active {
                            background: $primary;
                        }
                    }

                    &.range-start {
                        &.active {
                            border-radius: 50% 0 0 50%;
                        }

                        &.selected {
                            border-radius: 50% 0 0 50%;
                        }
                    }

                    &.range-end {
                        &.active {
                            border-radius: 0 50% 50% 0;
                        }

                        &.selected {
                            border-radius: 0 50% 50% 0;
                        }
                    }
                }
            }
        }
    }

    .datepicker-months {
        table {
            thead {
                font-weight: bold;
                font-size: 12px;
            }

            tbody {
                .month {
                    line-height: 40px;
                    height: 40px;
                    font-family: $header-font-family;
                    font-size: 12px;

                    &.active {
                        background: $primary;

                        &:hover {
                            background: darken($primary, 10%) !important;
                        } 
                    }
                }
            }
        }
    }

    .datepicker-years {
        table {
            thead {
                font-weight: bold;
                font-size: 12px;
            }

            tbody {
                .year {
                    line-height: 40px;
                    height: 40px;
                    font-family: $header-font-family;
                    font-size: 12px;

                    &.active {
                        background: $primary;

                        &:hover {
                            background: darken($primary, 10%) !important;
                        } 
                    }
                }
            }
        }
    }

    .datepicker-decades {
        table {
            thead {
                font-weight: bold;
                font-size: 12px;
            }

            tbody {
                .decade {
                    line-height: 40px;
                    height: 40px;
                    font-family: $header-font-family;
                    font-size: 12px;

                    &.active {
                        background: $primary;

                        &:hover {
                            background: darken($primary, 10%) !important;
                        } 
                    }
                }
            }
        }
    }

    .datepicker-centuries {
        table {
            thead {
                font-weight: bold;
                font-size: 12px;
            }

            tbody {
                .century {
                    line-height: 40px;
                    height: 40px;
                    font-family: $header-font-family;
                    font-size: 12px;

                    &.active {
                        background: $primary;

                        &:hover {
                            background: darken($primary, 10%) !important;
                        } 
                    }
                }
            }
        }
    }
}

/* ====== Date Range Picker ===== 
------------------------------------------------------------ */
.daterangepicker {
    padding: 20px 20px;
    border-color: #f9f9f9;

    &:before {
        border-bottom-color: #f9f9f9;
    }

    .calendar {
        margin: 0 0 10px 0;

        .daterangepicker_input {
            input {
                border-radius: 0 !important;
                padding: 5px 15px 5px 40px;
                height: 40px;
                line-height: 30px;
                border-color: #ddd;

                &.active {
                    border-color: #ddd;
                }
            }

            i {
                left: 12px;
                top: 12px;
            }
        }

        .calendar-table {
            table {
                thead {
                    tr {
                        th {
                            font-size: 12px;
                            font-weight: 600;    
                        }
                        
                        &:nth-child(2) {
                            th {
                                font-family: $header-font-family;
                                text-transform: uppercase;
                                font-size: 11px;
                                color: $primary;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 12px;
                            padding: 7px 10px;
                            color: lighten($font-color, 10%);

                            &.active {
                                &.start-date.end-date {
                                    border-radius: 50%;
                                }

                                &.start-date,
                                &.end-date {
                                    background: $primary !important;
                                    color: $white;
                                }

                                &.start-date {
                                    border-radius: 50% 0 0 50%;
                                }

                                &.end-date {
                                    border-radius: 0 50% 50% 0;
                                }
                            }

                            &.in-range {
                                color: $black;

                                &.available {
                                    background: #eeeeee;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ranges {
        margin: 0 15px;

        ul {
            width: 100%;
            margin-bottom: 20px;

            li {
                padding: 10px 15px;
                background: #f9f9f9;
                color: $font-color;
                border-color: #f9f9f9;
                font-family: $header-font-family;
                text-transform: uppercase;
                font-size: 11px;

                &.active {
                    background: $primary;
                    color: $white;
                    border-color: $primary;
                }

                &:hover {
                    background: $primary;
                    color: $white;
                }
            }
        }

        .range_inputs {
            button {
                width: 100px;
                margin-right: 10px;

                &.applyBtn {
                    background: $primary;
                    border-color: $primary;
                }
            }
        }
    }
}

/* ====== jQuery Steps ===== 
------------------------------------------------------------ */
.wizard {
    .steps {
        > ul {
            > li {
                > a {
                    margin: 0;
                    border-radius: 0;
                    color: $font-color;
                    background: transparent !important;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                
                    &:hover {
                        margin: 0 !important;
                        color: $black;
                        border-radius: none;
                    }
                }

                &.current {
                    border-bottom: solid 3px $primary;

                    a {
                        color: $black;

                        &:hover {
                            color: $black;
                        }
                    }
                }

                &.done {
                    border-bottom: solid 3px $primary;

                    a {
                        color: $black;

                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    .content {
        margin: 0;
        background: $white;

        .body {
            label {
                &.error {
                    margin-left: 0;
                    color: $danger;
                }
            }

            input {
                &.error {
                    color: $danger;
                    background: $white;
                    border-color: $danger;
                }
            }
        }
    }

    .actions {
        background: $white;
        padding: 25px;

        > ul {
            li {
                margin-bottom: 15px;
                
                a {
                    background: $primary;
                    padding: 10px 15px;
                    width: 150px;
                    text-align: center;

                    &:hover {
                        background: $primary;
                        padding: 10px 15px;
                        width: 150px;
                    }
                }
            }
        }
    }
}


/* ====== Light Gallery ===== 
------------------------------------------------------------ */
#select-trans {
    margin-left: 80px;
    margin-bottom: 20px;
}

.lightgallery-img {
    li {
        overflow: hidden;
        cursor: pointer;
        position: relative;
        padding: 0;
        margin-bottom: 10px;
        margin-right: 10px;
        // border-radius: 4px;
        // @include translate2d(0, 0);

        &:before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
            // background: rgba(0, 0, 0, 0.25);
            z-index: 999;
        }

        &:after {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-family: "FontAwesome";
            content: "\f002";
            line-height: 100px;
            color: rgba(255, 255, 255, 0.85);
            font-size: 28px;
            background: transparent;
            text-align: center;
            z-index: 1000;
            opacity: 0;
            @include translate3d(0, 10px, 0);
            @include transition (opacity 0.35s);
            @include transition (transform 0.35s);
        }

        &:hover {
            &:before {
                display: block;
            }

            &:after {
                opacity: 1;
                @include translate3d(0, 0, 0);
            }

            img {
                // transform: scale(1.1);
            }
        }

        img {
            transform: scale(1);
            @include transition(transform 0.4s);
        }
    }
}



/* ============================
    Progress Bar
============================ */
.progress {
    height: 10px;
    border-radius: 50px;
    box-shadow: none;
    background: #e1e1e1;

    .progress-bar {
        border-radius: 50px;
        background: $primary;
        font-size: 8px;
        line-height: 10px;

        &.progress-bar-primary {
            background: $primary;            
        }

        &.progress-bar-success {
            background: $success;            
        }

        &.progress-bar-info {
            background: $info;            
        }

        &.progress-bar-warning {
            background: $warning;            
        }

        &.progress-bar-danger {
            background: $danger;            
        }
    }
}
