/* ----------------------------------------------------
 *  10. Tooltip
 *  
 *  Contains the over ride CSS for bootstrap tooltips
 *  and extra contextual tooltip CSS
 * ---------------------------------------------------- */


/* ====== Tooltip Override ===== 
------------------------------------------------------------ */
.tooltip {
    font-family: $font-family;
    text-transform: uppercase;
    font-size: $font-size-sm;
    z-index: 99999;

    > .tooltip-inner {
        padding: 10px 15px;
        @include border-radius(2px);
    }

    &.in {
        opacity: 1;
    }
}


/* ====== Tooltip Primary ===== 
------------------------------------------------------------ */
.tooltip-primary {
    + .tooltip {
        > .tooltip-inner {
            background-color: $primary;
        }
        
        &.top {
            > .tooltip-arrow {
                border-top-color: $primary;
            }
        }

        &.right {
            > .tooltip-arrow {
                border-right-color: $primary;
            }
        }

        &.bottom {
            > .tooltip-arrow {
                border-bottom-color: $primary;
            }
        }

        &.left {
            > .tooltip-arrow {
                border-left-color: $primary;
            }
        }
    }
}


/* ====== Tooltip Success ===== 
------------------------------------------------------------ */
.tooltip-success {
    + .tooltip {
        > .tooltip-inner {
            background-color: $success;
        }
        
        &.top {
            > .tooltip-arrow {
                border-top-color: $success;
            }
        }

        &.right {
            > .tooltip-arrow {
                border-right-color: $success;
            }
        }

        &.bottom {
            > .tooltip-arrow {
                border-bottom-color: $success;
            }
        }

        &.left {
            > .tooltip-arrow {
                border-left-color: $success;
            }
        }
    }
}


/* ====== Tooltip Info ===== 
------------------------------------------------------------ */
.tooltip-info {
    + .tooltip {
        > .tooltip-inner {
            background-color: $info;
        }
        
        &.top {
            > .tooltip-arrow {
                border-top-color: $info;
            }
        }

        &.right {
            > .tooltip-arrow {
                border-right-color: $info;
            }
        }

        &.bottom {
            > .tooltip-arrow {
                border-bottom-color: $info;
            }
        }

        &.left {
            > .tooltip-arrow {
                border-left-color: $info;
            }
        }
    }
}


/* ====== Tooltip Warning ===== 
------------------------------------------------------------ */
.tooltip-warning {
    + .tooltip {
        > .tooltip-inner {
            background-color: $warning;
        }
        
        &.top {
            > .tooltip-arrow {
                border-top-color: $warning;
            }
        }

        &.right {
            > .tooltip-arrow {
                border-right-color: $warning;
            }
        }

        &.bottom {
            > .tooltip-arrow {
                border-bottom-color: $warning;
            }
        }

        &.left {
            > .tooltip-arrow {
                border-left-color: $warning;
            }
        }
    }
}


/* ====== Tooltip Danger ===== 
------------------------------------------------------------ */
.tooltip-danger {
    + .tooltip {
        > .tooltip-inner {
            background-color: $danger;
        }
        
        &.top {
            > .tooltip-arrow {
                border-top-color: $danger;
            }
        }

        &.right {
            > .tooltip-arrow {
                border-right-color: $danger;
            }
        }

        &.bottom {
            > .tooltip-arrow {
                border-bottom-color: $danger;
            }
        }

        &.left {
            > .tooltip-arrow {
                border-left-color: $danger;
            }
        }
    }
}


/* ====== Alerts Override ===== 
------------------------------------------------------------ */
.alert {
    font-family: $font-family;
    padding: 15px 15px;
    @include border-radius(4px);
    border: none;
    box-shadow: 0 7px 4px -3px rgba(0,0,0,0.3);

    &.alert-primary {
        background: lighten($primary, 35%);
        color: darken($primary, 15%);

        .close {
            color: darken($primary, 15%);
        }
    }

    &.alert-success {
        background: lighten($success, 45%);
        color: darken($success, 15%);

        .close {
            color: darken($success, 15%);
        }
    }

    &.alert-info {
        background: lighten($info, 45%);
        color: darken($info, 15%);

        .close {
            color: darken($info, 15%);
        }
    }

    &.alert-warning {
        background: lighten($warning, 40%);
        color: darken($warning, 15%);

        .close {
            color: darken($warning, 15%);
        }
    }

    &.alert-danger {
        background: lighten($danger, 36%);
        color: darken($danger, 15%);

        .close {
            color: darken($danger, 15%);
        }
    }
}


/* ====== Labels ===== 
------------------------------------------------------------ */
.label {
    font-family: $font-family;
    @include border-radius(2px);   
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;

    &.label-primary {
        background: $primary;
    }

    &.label-success {
        background: $success;
    }

    &.label-info {
        background: $info;
    }

    &.label-warning {
        background: $warning;
    }

    &.label-danger {
        background: $danger;
    }
}


/* ====== Badges ===== 
------------------------------------------------------------ */
.badge {
    font-family: $font-family;
    font-weight: normal;

    &.badge-primary {
        background: $primary;
    }

    &.badge-success {
        background: $success;
    }

    &.badge-info {
        background: $info;
    }

    &.badge-warning {
        background: $warning;
    }

    &.badge-danger {
        background: $danger;
    }
}


/* ====== List Group ===== 
------------------------------------------------------------ */
.list-group {
    .list-group-item {
        &.list-group-item-primary {
            background: lighten($primary, 35%);
            color: darken($primary, 15%);
        }

        &.list-group-item-success {
            background: lighten($success, 45%);
            color: darken($success, 15%);
        }

        &.list-group-item-info {
            background: lighten($info, 45%);
            color: darken($info, 15%);   
        }

        &.list-group-item-warning {
            background: lighten($warning, 40%);
            color: darken($warning, 15%);      
        }

        &.list-group-item-danger {
            background: lighten($danger, 36%);
            color: darken($info, 15%);
        }
    }
}