/* -----------------------------------------------------------------------
 *  Main CSS file for the app
 *
 * 
 *	Table of Contents
 *	
 *  1. Utilities
 *  2. Reset
 *  3. Layout
 *  4. Navigation
 *  5. Panels
 *  6. Tabs
 *  7. Accordion
 *  8. Forms
 *  9. Pricing
 *  10. Tooltip
 *  11. Popover
 *  12. Masonry
 *  13. Sortable
 *  14. Sliders
 *  15. Timeline
 *  16. Rating
 *  17. Tables
 *  18. Modals
 *  19. Widgets
 *  20. Notifications
 *  21. Login & Register
 *  22. Mail
 *  23. Todo
 *  24. Calendar
 *  25. Profile
 * ----------------------------------------------------------------------- */


// Helpers
@import 'helpers/_mixins';
@import 'helpers/_variables';
@import 'helpers/_utilities';

// Base
@import 'base/_reset';
@import 'base/_layout';
@import 'base/_navigation';

// Components
@import 'components/_panels';
@import 'components/_tabs';
@import 'components/_accordion';
@import 'components/_forms';
@import 'components/_pricing';
@import 'components/_tooltip';
@import 'components/_popover';
@import 'components/_masonry';
@import 'components/_sortable';
@import 'components/_sliders';
@import 'components/_timeline';
@import 'components/_rating';
@import 'components/_tables';
@import 'components/_modals';
@import 'components/_widgets';
@import 'components/_notifications';
@import 'components/_login-and-register';

// Apps
@import 'app/_mail';
@import 'app/_todo';
@import 'app/_calendar';
@import 'app/_profile';

// Plugins
@import '../scripts/plugins/datatables-fixedheader/css/fixedHeader.dataTables.scss';