/* ----------------------------------------------------
 *  15. Timeline
 *
 *  Contains the CSS for the timeline
 * ---------------------------------------------------- */


.timeline-container {
    // background: $white;
    padding: 15px;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: relative;

        &:before {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 3px;
            background: #D7E4ED;
            @include border-radius(5px);
        }

        li {
            padding: 15px;
            margin-bottom: 50px;
            position: relative;            
            overflow: hidden;

            &.timeline {
                .timeline-incident {
                    .timeline-incident-moment {
                        width: 46%;
                        color: #aaa;
                        padding-top: 15px;
                        height: 58px;
                        line-height: 58px;

                        .timeline-incident-icon {
                            position: absolute;
                            left: 0%;
                            right: 0%;
                            width: 58px;
                            height: 58px;
                            line-height: 58px;
                            text-align: center;
                            background: $warning;
                            color: $white;
                            border-radius: 50%;
                            border: solid 4px #fff;
                            box-shadow: 0 1px 2px #ddd;

                            margin: 0 auto;

                            i {
                                height: 50px;
                                line-height: 50px;
                            }
                        }
                    }
                    .timeline-incident-body {
                        margin: 0;
                        width: 46%;
                        padding: 0 5px 25px;
                        border-bottom: solid 3px #D7E4ED;

                        .timeline-header {
                            margin: 0 0 10px;
                        }
                    }
                }
            }

            &:nth-child(odd) {
                .timeline-incident {
                    .timeline-incident-moment {
                        float: left;
                        text-align: right;
                        
                    }
                    .timeline-incident-body {
                        float: right;
                        text-align: left;
                    }
                }
            }

            &:nth-child(even) {
                .timeline-incident {
                    .timeline-incident-moment {
                        float: right;
                        text-align: left;
                    }
                    .timeline-incident-body {
                        float: left;
                        text-align: right;
                    }
                }
            }
        }
    }
}