/* ----------------------------------------------------
 *  8. Forms
 *  
 *  Contains both overridden and extra CSS
 *  that are added for all the components
 *  that we are using.
 * ---------------------------------------------------- */


/* ------------------------- Begin Input ------------------------- */

.form-group-default {
	position: relative;
	padding-top: 23px;

	label {
		text-transform: uppercase;
		position: absolute;
		display: block;
		top: 0;
		left: 15px;

		&:first-child {
			@include translate3d(0, 38px, 0);
			@include transition(transform 0.2s ease);
		}
	}

	&.form-group-default-lg {
		label {
			&:first-child {
				@include translate3d(0, 43px, 0);
				@include transition(transform 0.2s ease);
			}
		}

		input {
			height: 55px;
			line-height: 35px;
		}
	}

	&.form-group-default-sm {
		label {
			&:first-child {
				@include translate3d(0, 33px, 0);
				@include transition(transform 0.2s ease);
			}
		}

		input {
			height: 35px;
			padding: 5px 10px;
			line-height: 25px;
		}
	}

	&.input--filled {
		label {
			&:first-child {
				@include translate3d(0, 0, 0);
			}
		}

		input {
			background: #e5e5e5;
		}
	}
}

/* ------------------------- End Input ------------------------- */


/* ------------------------- Begin Material Design Input ------------------------- */

.md-group { 
	position: relative;
	margin-bottom: 45px;	

	input {
		font-size: 13px;
		padding: 10px 10px 10px 5px;
		display: block;
		border: none;
		border-bottom: 1px solid #757575;

		&:focus {
			outline: none;
		}

		&:focus ~ label, 
		&:valid ~ label {
			top: -20px;
			font-size: 13px;
			color: #5264AE;
		}

		&:focus ~ .md-bar:before, 
		&:focus ~ .md-bar:after {
			width: 50%;
		}

		&:focus ~ .highlight {
			-webkit-animation: inputHighlighter 0.3s ease;
			-moz-animation: inputHighlighter 0.3s ease;
			-ms-animation: inputHighlighter 0.3s ease;
			-o-animation: inputHighlighter 0.3s ease;
			animation: inputHighlighter 0.3s ease;
		}
	}

	label {
		color: #999;
		font-size: 11px;
		font-weight: normal;
		font-weight: 600;
		position: absolute;
		pointer-events: none;
		text-transform: uppercase;
		margin: 0;
		// left: 5px;
		top: 15px;
		-webkit-transition: 0.2s ease all;
		-moz-transition: 0.2s ease all; 
		-ms-transition: 0.2s ease all; 
		-o-transition: 0.2s ease all; 
		transition: 0.2s ease all; 
	}

	.md-bar {
		position: relative; 
		display: block; 

		&:before, 
		&:after {
			content: '';
			height: 2px; 
			width: 0;
			bottom: 1px; 
			position: absolute;
			background: #5264AE; 
			transition: 0.2s ease all; 
			-moz-transition: 0.2s ease all; 
			-webkit-transition: 0.2s ease all;
		}

		&:before {
			left: 50%;
		}

		&:after {
			right: 50%; 
		}
	}

	.md-highlight {
		position: absolute;
		height: 60%; 
		width: auto;
		top: 25%; 
		left: 0;
		pointer-events: none;
		opacity: 0.5;
	}
}


/* ==== Input Animation ==== */
@-webkit-keyframes inputHighlighter {
	from {
		background: #5264AE;
	}
	
	to {
		width: 0;
		background: transparent;
	}
}
@-moz-keyframes inputHighlighter {
	from {
		background: #5264AE;
	}
	
	to {
		width: 0;
		background: transparent;
	}
}
@-ms-keyframes inputHighlighter {
	from {
		background: #5264AE;
	}
	
	to {
		width: 0;
		background: transparent;
	}
}
@-o-keyframes inputHighlighter {
	from {
		background: #5264AE;
	}
	
	to {
		width: 0;
		background: transparent;
	}
}
@keyframes inputHighlighter {
	from {
		background: #5264AE;
	}
	
	to {
		width: 0;
		background: transparent;
	}
}
/* ------------------------- End Material Design Input ------------------------- */


/* ------------------------- Begin Checkbox ------------------------- */

.checkbox-default {
	position: relative;
	line-height: 135%;
	cursor: pointer;

	> input {
		position: relative;
		margin: 0 5px 0 0;
		cursor: pointer;
		display: none;

		&:checked {
			~ span {
				&:before {
					@include transform(rotate(-45deg));
					height: 8px;
					top: 0px;
					border-color: $primary;
					border-top-style: none;
					border-right-style: none;
				}
			}   
		}
	}

	> span {
		margin-left: 5px;

		&:before {
			@include transition(all .3s ease-in-out);
			content: "";
			position: absolute;
			left: 0;
			top: -1px;
			z-index: 1;
			width: 18px;
			height: 18px;
			border: 2px solid #ddd; 
		}
	}

	&.primary {
		input {
			&:checked {
				~ span {
					&:before {
						border-color: $primary;
					}
				}   
			}   
		}
	}

	&.success {
		input {
			&:checked {
				~ span {
					&:before {
						border-color: $success;
					}
				}   
			}
		}
	}

	&.info {
		input {
			&:checked {
				~ span {
					&:before {
						border-color: $info;
					}
				}   
			}
		}
	}

	&.warning {
		input {
			&:checked {
				~ span {
					&:before {
						border-color: $warning;
					} 
				}   
			}
		}
	}

	&.danger {
		input {
			&:checked {
				~ span {
					&:before {
						border-color: $danger;
					} 
				}   
			}
		}
	}
}

/* ------------------------- End Checkbox ------------------------- */


/* ------------------------- Begin Radio ------------------------- */

.radio-default {
	/* Remove default Radio Buttons */
	[type="radio"]:not(:checked),
	[type="radio"]:checked {
		position: absolute;
		left: -9999px;
	}

	[type="radio"]:not(:checked) + label,
	[type="radio"]:checked + label {
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		display: inline-block;
		height: 25px;
		line-height: 25px;
		font-size: 13px;
		@include transition(.28s ease);

		-webkit-user-select: none; /* webkit (safari, chrome) browsers */
		-moz-user-select: none; /* mozilla browsers */
		-khtml-user-select: none; /* webkit (konqueror) browsers */
		-ms-user-select: none; /* IE10+ */
	}

	[type="radio"] + label:before,
	[type="radio"] + label:after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		margin: 4px;
		width: 16px;
		height: 16px;
		z-index: 0;
		@include transition(.28s ease);
	}

	/* Unchecked styles */
	[type="radio"]:not(:checked) + label:before {
		border-radius: 50%;
		border: 2px solid #5a5a5a;
	}
	[type="radio"]:not(:checked) + label:after {
		border-radius: 50%;
		border: 2px solid #5a5a5a;
		z-index: -1;

		@include transform(scale(0));
	}

	/* Checked styles */
	[type="radio"]:checked + label:before {
		border-radius: 50%;
		border: 2px solid transparent;
	}
	[type="radio"]:checked + label:after {
		border-radius: 50%;
		border: 2px solid $primary;
		background-color: $primary;
		z-index: 0;
		@include transform(scale(1.02));
	}

	/* Radio With gap */
	[type="radio"].with-gap:checked + label:before {
		border-radius: 50%;
		border: 2px solid $primary;
	}
	[type="radio"].with-gap:checked + label:after {
		border-radius: 50%;
		border: 2px solid $primary;
		background-color: $primary;
		z-index: 0;
		@include transform(scale(.5));
	}

	/* Disabled style */
	[type="radio"]:disabled:not(:checked) + label:before,
	[type="radio"]:disabled:checked + label:before {
		background-color: transparent;
		border-color: rgba(0,0,0, .26);
	}
	[type="radio"]:disabled + label {
		color: rgba(0,0,0, .26);
	}
	[type="radio"]:disabled:not(:checked) + label:hover:before {
		border-color: rgba(0,0,0, .26);
	}

	&.primary {
		[type="radio"]:checked + label:after {
			border: 2px solid $primary;
			background-color: $primary;
		}

		/* Radio With gap */
		[type="radio"].with-gap:checked + label:before {
			border: 2px solid $primary;
		}
		[type="radio"].with-gap:checked + label:after {
			border: 2px solid $primary;
			background-color: $primary;
		}
	}

	&.success {
		[type="radio"]:checked + label:after {
			border: 2px solid $success;
			background-color: $success;
		}

		/* Radio With gap */
		[type="radio"].with-gap:checked + label:before {
			border: 2px solid $success;
		}
		[type="radio"].with-gap:checked + label:after {
			border: 2px solid $success;
			background-color: $success;
		}
	}

	&.info {
		[type="radio"]:checked + label:after {
			border: 2px solid $info;
			background-color: $info;
		}

		/* Radio With gap */
		[type="radio"].with-gap:checked + label:before {
			border: 2px solid $info;
		}
		[type="radio"].with-gap:checked + label:after {
			border: 2px solid $info;
			background-color: $info;
		}
	}

	&.warning {
		[type="radio"]:checked + label:after {
			border: 2px solid $warning;
			background-color: $warning;
		}

		/* Radio With gap */
		[type="radio"].with-gap:checked + label:before {
			border: 2px solid $warning;
		}
		[type="radio"].with-gap:checked + label:after {
			border: 2px solid $warning;
			background-color: $warning;
		}
	}

	&.danger {
		[type="radio"]:checked + label:after {
			border: 2px solid $danger;
			background-color: $danger;
		}

		/* Radio With gap */
		[type="radio"].with-gap:checked + label:before {
			border: 2px solid $danger;
		}
		[type="radio"].with-gap:checked + label:after {
			border: 2px solid $danger;
			background-color: $danger;
		}
	}
}

/* ------------------------- End Radio ------------------------- */


/* ------------------------- Begin Switch ------------------------- */

.switch,
.switch * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.switch {
	margin: 10px 0;

	label {
		cursor: pointer;
		font-family: $header-font-family;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.03em;

		input[type=checkbox] {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked + .lever {
				background-color: desaturate(lighten($primary, 25%), 25%);
			}

			&:checked + .lever:after {
				background-color: $primary;
			}

			&:checked:not(:disabled) ~ .lever:active:after {
				   box-shadow: 0 1px 3px 1px rgba(0,0,0,0.4), 0 0 0 15px transparentize($primary, .9);
			}

			&:not(:disabled) ~ .lever:active:after {
				   box-shadow: 0 1px 3px 1px rgba(0,0,0,0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
			}

			&:checked + .lever:after {
				left: 24px;
			}
		}

		.lever {
			content: "";
			display: inline-block;
			position: relative;
			width: 40px;
			height: 15px;
			background-color: desaturate(lighten($primary, 25%), 25%);
			border-radius: 15px;
			margin-right: 10px;
			transition: background 0.3s ease;
			vertical-align: middle;
			margin: 0px 16px;

			&:after {
				content: "";
				position: absolute;
				display: inline-block;
				width: 21px;
				height: 21px;
				background-color: #F1F1F1;
				border-radius: 21px;
				box-shadow: 0 1px 3px 1px rgba(0,0,0,0.4);
				left: -5px;
				top: -3px;
				transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
			}
		}
	}

	&.primary {
		label {
			input[type=checkbox] {
				&:checked + .lever {
					background-color: desaturate(lighten($primary, 25%), 25%);
				}

				&:checked + .lever:after {
					background-color: $primary;
				}
			}
		}
	}

	&.success {
		label {
			input[type=checkbox] {
				&:checked + .lever {
					background-color: desaturate(lighten($success, 25%), 25%);
				}

				&:checked + .lever:after {
					background-color: $success;
				}
			}
		}
	}

	&.info {
		label {
			input[type=checkbox] {
				&:checked + .lever {
					background-color: desaturate(lighten($info, 25%), 25%);
				}

				&:checked + .lever:after {
					background-color: $info;
				}
			}
		}
	}

	&.warning {
		label {
			input[type=checkbox] {
				&:checked + .lever {
					background-color: desaturate(lighten($warning, 25%), 25%);
				}

				&:checked + .lever:after {
					background-color: $warning;
				}
			}
		}
	}

	&.danger {
		label {
			input[type=checkbox] {
				&:checked + .lever {
					background-color: desaturate(lighten($danger, 25%), 25%);
				}

				&:checked + .lever:after {
					background-color: $danger;
				}
			}
		}
	}
}

// Disabled Styles
.switch input[type=checkbox][disabled] + .lever{
  cursor: default;
}
.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
	background-color: #BDBDBD;
}

/* ------------------------- End Switch ------------------------- */


/* ------------------------- Begin Form Horizontal ------------------------- */

.form-horizontal {
	.control-label {
		padding: 0 15px;
		line-height: 45px;
	}
}

/* ------------------------- End Form Horizontal ------------------------- */


/*
 *  Validation
 * ========================================================= */
.form-group {
	input.error {
		border-color: $danger;
	}

	input.valid {
		border-color: $success;
	}

	label.error {
		display: block;
		position: static;
		font-weight: normal;
		font-size: $font-size-sm;
		color: $danger;
		padding: 5px 0;
	}

	&.form-group-default {
		label.error {
			padding: 5px 5px;
		}
	}
}




/*
 *  Dropzone
 * ========================================================= */

.dropzone {
	min-height: 200px;
	border: dashed 2px #ddd;
	position: relative;
	margin-bottom: 15px;

	.dz-message {
		font-family: $header-font-family;
		margin: 40px 0;
	}
}



/*
 *  Typeahead
 * ========================================================= */
 .twitter-typeahead {
 	width: 100%;

 	.tt-menu {
 		width: 100%;
 		background: $white;

 		.tt-dataset {
 			.tt-suggestion {
				padding: 15px;
				border-bottom: solid 1px #f9f9f9;

				&.tt-cursor {
					background: lighten($info, 52%);
				}

				&:hover {
					background: lighten($info, 52%);
				}
 			}
 		}
 	}
 }



 /*
 *  Select 2
 * ========================================================= */
  
.select2 {
	display: block;
	width: 100% !important;

	.selection {
		.select2-selection {
			padding: 10px 15px;
			border: 1px solid #e5e5e5;
			box-shadow: none;
			background: $white;
			border-radius: 0;

			&.select2-selection--single {
				height: 45px;

				.select-selection__rendered {
					line-height: 25px;
				}
			}

			.select2-selection__rendered {
				padding: 0;
				font-size: 14px;

				.select2-selection__choice {
					margin: 0 5px 5px 0;
					background: #eee;
				}
			}

			.select2-selection__arrow {
				height: 45px;
				right: 10px;
			}
		}
	}
}