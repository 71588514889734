/* ----------------------------------------------------
 *  13. Sortable
 *
 *  Contains the over ride CSS for jquery sortable
 * ---------------------------------------------------- */


.sortable {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.sortable-list {
        li {
            padding: 10px;
            height: 40px;
            text-transform: uppercase;
            line-height: 20px;
            border: solid 1px;
            border-color: #f7f7f7;
            margin-bottom: 5px;
            font-weight: 700;
            background: #f7f7f7;
            cursor: move;

            &.disabled {
                opacity: 0.5;
            }
        }

        .sortable-placeholder {
            border: dashed 1px #bbb;
            background: $white;
        }
    }

    &.sortable-list-handles {
        li {
            padding: 10px;
            height: 40px;
            text-transform: uppercase;
            line-height: 20px;
            border: solid 1px;
            border-color: #f7f7f7;
            margin-bottom: 5px;
            font-weight: 700;
            background: #f7f7f7;
            cursor: default;

            span {
                cursor: move;
            }

            &.disabled {
                opacity: 0.5;
            }
        }

        .sortable-placeholder {
            border: dashed 1px #bbb;
            background: $white;
        }
    }

    &.sortable-grid {
        li {
            float: left;
            margin-right: 10px;
            margin-bottom: 10px;
            height: 100px;
            width: 100px;
            background: #ddd;
            line-height: 100px;
            border: solid 1px;
            border-color: #ddd;
            text-align: center;
            cursor: move;
        }

        .sortable-placeholder {
            border: dashed 1px #bbb;
            background: $white;
        }
    }
}