/* ----------------------------------------------------
 *  3. Layout
 *
 *  Base part for the layout of the application
 *  
 *  Description: Contains all the basic and responsive
 *  				layout for the app.
 *
 *  Reference Class or Main Elements
 *  	.content-wrapper
 *  		#page-sidebar
 *  		#page-header
 *  		#page-content
 *  		#page-footer
 *  		.navbar-collapsed
 *  		@media
 * ---------------------------------------------------- */



.content-wrapper {
	position: relative;
	height: auto;
	min-height: 100%;

	&.mail-wrapper {
		height: 100% !important;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #f1f1f1;
		z-index: -1;
	}

	#page-sidebar {
		position: fixed;
		width: 250px;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 999;
		padding-top: 50px;
		overflow: hidden;

		div.sidebar-header {
			// background: darken($sidebar-bg, 2%);
			background: $sidebar-bg;
			position: fixed;
			top: 0;
			width: 250px;
			left: 0;
			z-index: 999;
		}
	}

	#page-header {
		height: 50px;
		background: #fff;
		box-shadow: 0 2px 0 -1px rgba(0,0,0,0.1);
		position: fixed;
		top: 0;
		right: 0;
		left: 250px;
		z-index: 990;

		#header-notifications-list {
			> a {
				&:before {
					content: '';
					display: block;
					position: absolute;
					height: 6px;
					width: 6px;
					border-radius: 50%;
					background: $danger;
					top: 14px;
					right: 8px;
				}
			}
		}

		.profile-dropdown {
			top: 101%;
			padding: 0;
			border: none;
			// box-shadow: 0 2px 4px #ddd;
			background: $white;
			overflow: hidden;
			border-radius: 0 4px;

			.profile-menu-container {
				width: 300px;

				.media {
					padding: 25px 0;

					.profile-image {
						border-radius: 50%;
						padding: 0 10px;
					}

					.media-body {
						padding: 0px 15px;
					}
				}

				.profile-options {
					overflow: hidden;

					> div {
						width: 100px;
						float: left;
						background: #292E31;

						a {
							color: $white;
							padding: 15px 0;
							font-size: 16px;
							display: block;
							text-decoration: none;

							&:hover {
								color: $white;
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		.nav {
			> li {
				> a:focus,
				> a:hover {
					background: transparent;
					color: $font-color;
				}
			}
		}
	}

	#page-content {
		margin-left: 250px;
		padding: 75px 25px 80px 25px;
	}

	#page-footer {
		height: 70px;
		line-height: 70px;
		padding: 0 40px;
		position: absolute;
		bottom :0;
		right: 0;
		left: 250px;

		> p {
			line-height: 70px;
		}
	}

	> .error {
		#page-footer {
			left: 0;
		}
	}
}



// Collapsed Navbar
.navbar-collapsed {
	#page-header {
		left: 75px;
	}

	#page-sidebar {
		width: 75px;
		@include transition(width 0.3s ease);

		&:hover {
			width: 250px;

			.sidebar-header {
				width: 250px !important;

				a {
					span {
						opacity: 1;
						@include transition(opacity 0.3s 0.2s ease);
					}
				}
			}

			.sidebar-avatar {
				padding: 0 15px !important;

				.media {
					.media-left {
						img {
							height: 64px;
							width: 64px;
						}
					}

					.media-body {
						opacity: 1;
						@include transition(opacity 0.3s 0.2s ease);
					}
				}
			}

			.sidebar-navigation {
				li {
					&.menu-header {
						opacity: 1;
						@include transition(opacity 0.3s 0.2s ease);
					}

					a {
						span {
							opacity: 1;
							@include transition(opacity 0.3s 0.2s ease);
						}

						i {
							right: 25px !important;
						}
					}

					&.open {
						ul.nav-submenu {
							height: auto !important;
						}
					}
				}
			}

			.sidebar-stats {
				opacity: 1;
				@include transition(opacity 0.3s 0.2s ease);
			}
		}

		.sidebar-header {
			width: 75px !important;
			overflow: hidden;
			@include transition(width 0.3s ease);

			a {
				span {
					opacity: 0;
				}
			}
		}

		.sidebar-avatar {
			padding: 0 5px !important;
			@include transition(padding 0.3s ease);

			.media {
				height: 70px;

				.media-left {
					img {
						height: 64px;
						width: 64px;
					}
				}

				.media-body {
					opacity: 0;
				}
			}
		}

		.sidebar-navigation {
			li {
				&.menu-header {
					opacity: 0;
				}

				a {
					span {
						opacity: 0;
					}

					i {
						right: 30px !important;
					}
				}

				&.open {
					ul.nav-submenu {
						height: 0 !important;
					}
				}
			}
		}

		.sidebar-stats {
			opacity: 0;
		}

	}

	#page-content {
		margin-left: 75px;
	}

	#page-footer {
		left: 75px;
	}
}


@media (max-width: 768px) {
	.notify-container {
		&[data-position="top-left"],
		&[data-position="top-right"],
		&[data-position="bottom-left"],
		&[data-position="bottom-right"],
		&[data-position="top"],
		&[data-position="bottom"] {
			left: 10px !important;
			right: 10px !important;
		}

		.notify-wrapper {
			width: 300px !important;
		}
	}

	.profile-widget-compact {
		.profile-follow {
			.row {
				> div {
					padding: 15px !important;
				}
			}
		}
	}

	.register-container {
		width: 100%;
	}

	.mini-stats-container {
		.mini-stats-content {
			padding: 40px 40px;

			h1 {
				font-size: 24px;
			}
		}
	}

	.mini-user-widget {
		.media {
			.media-body {
				h3 {
					font-size: 15px;
				}
			}
		}
	}

	.email-widget {
		ul {
			li {
				width: auto !important;
			}
		}
	}


	body {
		&.navbar-visible {
			.content-wrapper {
				overflow: hidden;
				height: 100%;
				width: 100%;

				#page-sidebar {
					left: 0 !important;

					.sidebar-header {
						left: 0px !important;
						top: 0px !important;
					}
				}

				#page-header {
					left: 250px !important;

					.header-container {
						margin-right: -250px !important;
					}
				}

				#page-content {
					margin-left: 250px !important;
					margin-right: -250px !important;

				}
			}
		}

		.content-wrapper {
			&.mail {
				.mail-container {
					position: relative;
					overflow: hidden;

					.mail-list {
						width: calc(100% - 75px) !important;
					}

					.mail-message {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 75px;
						right: 0;
						width: calc(100% - 75px);
						z-index: 9999;
						@include translate2d(100%, 0);
						@include transition(transform 0.3s);

						&.mail-message-visible {
							@include translate2d(0, 0);
						}

						.mail-message-close {
							position: absolute;
							top: 0;
							right: 0;
							cursor: pointer;
						}
					}
				}
			}

			.lockscreen-container,
			.register-container,
			.login-container,
			.fp-container {
				width: 100%;
			}

			#page-header {
				left: 0;
				@include transition(left 0.3s ease);

				.header-container {
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					width: 150px;
					text-align: center;
					margin: 0 auto;
					height: 50px;
					line-height: 50px;
				}
			}

			#page-sidebar {
				left: -250px;	
				@include transition(left 0.3s ease);

				.sidebar-header {
					left: -250px;
					@include transition(left 0.3s ease);
				}
			}

			#page-content {
				margin-left: 0;
				margin-right: 0;
				transition: margin-left 0.3s ease, margin-right 0.3s ease;

				.todo-container {
					.todo-body {
						position: relative;
						overflow: hidden;

						.todo-aside {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							z-index: 9999;
							@include translate2d(-100%, 0);
							@include transition(transform 0.3s);

							&.todo-aside-visible {
								@include translate2d(0, 0);								
							}
						}
					}
				}

				.fc.fc-ltr {
					.fc-header-toolbar {
						// overflow: hidden;

						.fc-left {
							// float: none;
							// overflow: hidden;
							text-align: center;
							width: 100%;

							button {
								// padding: 0 5px;
								margin: 0;
							}

							h2 {
								margin: 0;
								font-size: 24px;
							}
						}

						.fc-right {
							float: none;
						}
					}
				}

				.tab-content {
					#simple-notification,
					#alert-notification {
						.notifications-dummy {
							.dummy-notification {
								height: 20px;
								width: 100px;
							}
						}
					}

					#thumb-notification,
					#thumb-circle-notification {
						.notifications-dummy {
							.dummy-notification {
								width: 100px;

								.dummy-notification-image {
									height: 30px;
									width: 30px;
								}

								.dummy-notification-content {
									width: 35px;
									left: 45px;
									top: 25px;
								}
							}
						}
					}

					#flip-notification {
						.notifications-dummy {
							.dummy-notification {
								width: 100px;
							}
						}
					}
				}

				#form-notify {
					.notification-style {
						ul {
							li {
								width: 33%;
							}
						}
					}
				}


				.timeline-container {
					.timeline-ul {
						&:before {
							margin: 0 0 0 28px !important;
						}

						.timeline {
							&:nth-child(even),
							&:nth-child(odd) {
								.timeline-incident {
									.timeline-incident-moment {
										width: 0% !important;
										height: 0;
										// margin-right: 15px !important;
										float: none !important;
										// text-align: right !important;
										span:first-child {
											position: absolute;
											top: -20px;
											left: 70px;
											z-index: 9999;
											// color: $black;
											// background: $white;
											// height: 20px;
											// height: 20px;
										}

										.timeline-incident-icon {
											margin: 0 !important;
										}
									}

									.timeline-incident-body {
										float: none !important;
										width: calc(100% - 50px);
										margin-left: 50px;
										text-align: left !important;
									}
								}
							}
						}
					}
				}

				.profile-stats {
					display: none;
				}
			}

			#page-footer {
				left: 0;
				@include transition(left 0.3s ease);

				p {
					text-align: center;

					.footer-author {
						display: none;
					}
				}
			}
		}
	}
}