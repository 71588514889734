/* ----------------------------------------------------
 *  11. Popover
 *
 *  Contains the over ride CSS for bootstrap popover
 *  and extra contextual tooltip CSS
 * ---------------------------------------------------- */


/* ====== Popover Override ===== 
------------------------------------------------------------ */
.popover {
    font-family: $font-family;
    border: none;
    box-shadow: 0 1px 2px #ddd;
    background: $white;
    color: $font-color;
    font-size: 13px;
    @include border-radius(2px);

    .popover-title {
        background: $black;
        color: $white;
        padding: 15px;
        text-transform: uppercase;
        font-size: 13px;
        @include border-top-radius(2px);
    }

    &.top {
        > .arrow {
            box-shadow: none;
            border-top-color: rgba(0, 0, 0, 0.07);
        }
    }

    &.right {
        > .arrow {
            box-shadow: none;
            border-right-color: rgba(0, 0, 0, 0.07);
        }
    }

    &.bottom {
        > .arrow {
            box-shadow: none;
            border-bottom-color: rgba(0, 0, 0, 0.07);
        }
    }

    &.left {
        > .arrow {
            box-shadow: none;
            border-left-color: rgba(0, 0, 0, 0.07);
        }
    }
}