/* ----------------------------------------------------
 *  16. Rating
 *
 *  Contains the over ride CSS for jquery rateYo
 * ---------------------------------------------------- */

.jq-ry-container {
 	position: relative;
 	padding: 0 5px;
 	line-height: 0;
 	display: block;
 	cursor: pointer;

 	.jq-ry-group-wrapper {
 		.jq-ry-normal-group {
 			svg {
	 			fill: #ddd;
	 		}
 		}

 		.jq-ry-rated-group {
 			svg {
 				fill: #e7c79b;
 			}
 		}
 	}
}

.rating-default {
	.jq-ry-rated-group {
			svg {
			fill: #e7c79b;
		}
	}
}

.rating-primary {
	.jq-ry-rated-group {
			svg {
			fill: $primary !important;
		}
	}
}

.rating-success {
	.jq-ry-rated-group {
			svg {
			fill: $success !important;
		}
	}
}

.rating-info {
	.jq-ry-rated-group {
			svg {
			fill: $info !important;
		}
	}
}

.rating-warning {
	.jq-ry-rated-group {
			svg {
			fill: $warning !important;
		}
	}
}

.rating-danger {
	.jq-ry-rated-group {
			svg {
			fill: $danger;
		}
	}
}

.jq-ry-container[readonly="readonly"] {
 	cursor: default;
}
.jq-ry-container > .jq-ry-group-wrapper {
 	position: relative;
 	width: 100%;
}
.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group {
 	position: relative;
 	line-height: 0;
 	z-index: 10;
 	white-space: nowrap;
}
 .jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group > svg {
 	display: inline-block;
}
.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-normal-group {
 	width: 100%;
}
.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-rated-group {
 	width: 0;
 	z-index: 11;
 	position: absolute;
 	top: 0;
 	left: 0;
 	overflow: hidden;
}
.rating-container {
	position: relative;
}
div.rating-container div.counter {
	display: none;
	position: absolute;
	min-width: 50px;
	text-align: center;
	padding: 0px;
	line-height: 32px;
	height: 32px;
	background: $black;
	color: $white;
	right: 0;
	top: 0;
}

div.rating-container:hover div.counter {
	display: block;
}