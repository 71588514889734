/* ----------------------------------------------------
 *  20. Notifications
 *
 *  Contains styling for the notifications which
 *  is exclusive for XPLOIT. Contains both the
 *  notiications CSS and notification demo CSS.
 * ---------------------------------------------------- */


/* ====== Demo - Notification Demo Tab ===== 
------------------------------------------------------------ */
.notification-tab {
	ul {
		li.active {
			h3 {
				position: relative;
				color: $white;
				background: $white !important;
				border: solid 5px $primary;

				&:before {
					height: 65px;
					width: 65px;
					line-height: 65px;
					background: rgba(0, 0, 0, 0);
					color: $primary;
					font-size: 36px;
					position: absolute;
					display: block;
					margin: auto;
					border-radius: 50%;
					font-family: "FontAwesome";
					content: "\f00c";
				}
			}
		}
	}
}

/* ====== Demo - Notification Styles ===== 
------------------------------------------------------------ */

.notification-style {
	li {
		border-bottom: solid 3px #ddd;
		padding: 10px 15px;
		cursor: pointer;
		text-transform: uppercase;
		font-weight: $fw-thk;
		letter-spacing: 1px;
		width: 19%;
		
		&.li-active {
			&:first-child {
				color: $primary;
				border-color: $primary;	
			}

			&:nth-child(2) {
				color: $success;
				border-color: $success;
			}

			&:nth-child(3) {
				color: $info;
				border-color: $info;
			}

			&:nth-child(4) {
				color: $warning;
				border-color: $warning;
			}

			&:nth-child(5) {
				color: $danger;
				border-color: $danger;
			}
		}
	}
}


/* ====== Demo - Notification Dummy ===== 
------------------------------------------------------------ */

.notifications-dummy {
	height: 350px;
	width: 100%;
	background: #f9f9f9;
	border: dashed 3px #ddd;
	position: relative;

	&.simple-notification-dummy,
	&.alert-notification-dummy {
		.dummy-notification {
			padding: 20px 15px;
			cursor: pointer;
			background: #ddd;
			border-radius: 4px;
			height: 50px;
			width: 150px;
			position: absolute;

			.dummy-notification-content {
				height: 10px;
				width: 75%;
				background: #aaa;
				border-radius: 4px;
			}

			.dummy-notification-close {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #aaa;
				position: absolute;
				top:10px;
				right: 10px;
			}
		}

		.dummy-top-left {
			top: 10px;
			left: 10px;
		}

		.dummy-top-right {
			top: 10px;
			right: 10px;
		}

		.dummy-bottom-left {
			bottom: 10px;
			left: 10px;
		}

		.dummy-bottom-right {
			bottom: 10px;
			right: 10px;
		}
	}

	&.thumb-notification-dummy {
		.dummy-notification {
			padding: 10px 10px;
			cursor: pointer;
			background: #ddd;
			height: 50px;
			width: 150px;
			position: absolute;

			.dummy-notification-image {
				height: 30px;
				width: 30px;
				background: #aaa;
				border-radius: 4px;
			}

			.dummy-notification-content {
				height: 10px;
				width: 70px;
				background: #aaa;
				border-radius: 4px;
				position: absolute;
				left: 50px;
				top: 20px;
			}

			.dummy-notification-close {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #aaa;
				position: absolute;
				top:10px;
				right: 10px;
			}
		}

		.dummy-top-left {
			top: 10px;
			left: 10px;
		}

		.dummy-top-right {
			top: 10px;
			right: 10px;
		}

		.dummy-bottom-left {
			bottom: 10px;
			left: 10px;
		}

		.dummy-bottom-right {
			bottom: 10px;
			right: 10px;
		}
	}


	&.flip-notification-dummy {
		.dummy-notification {
			padding: 20px 15px;
			cursor: pointer;
			background: #ddd;
			border-radius: 4px;
			height: 75px;
			width: 150px;
			position: absolute;

			.dummy-notification-content {
				height: 7px;
				width: 75%;
				background: #aaa;
				border-radius: 4px;
				position: relative;

				&:before {
					content: '';
					display: block;
					height: 7px;
					width: 50%;
					position: absolute;
					background: #aaa;
					border-radius: 4px;
					top: 15px;
				}

				&:after {
					content: '';
					display: block;
					height: 7px;
					width: 60%;
					position: absolute;
					background: #aaa;
					border-radius: 4px;
					top: 30px;
				}
			}

			.dummy-notification-close {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #aaa;
				position: absolute;
				top:10px;
				right: 10px;
			}
		}

		.dummy-top-left {
			top: 0;
			left: 10px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.dummy-top-right {
			top: 0;
			right: 10px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.dummy-bottom-left {
			bottom: 0;
			left: 10px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		.dummy-bottom-right {
			bottom: 0;
			right: 10px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&.thumb-circle-notification-dummy {
		.dummy-notification {
			padding: 10px 10px;
			cursor: pointer;
			background: #ddd;
			height: 50px;
			width: 150px;
			position: absolute;
			border-radius: 50px;

			.dummy-notification-image {
				height: 30px;
				width: 30px;
				background: #aaa;
				border-radius: 50%;
			}

			.dummy-notification-content {
				height: 10px;
				width: 70px;
				background: #aaa;
				border-radius: 4px;
				position: absolute;
				left: 45px;
				top: 20px;
			}

			.dummy-notification-close {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #aaa;
				position: absolute;
				top:10px;
				right: 15px;
			}
		}

		.dummy-top-left {
			top: 10px;
			left: 10px;
		}

		.dummy-top-right {
			top: 10px;
			right: 10px;
		}

		.dummy-bottom-left {
			bottom: 10px;
			left: 10px;
		}

		.dummy-bottom-right {
			bottom: 10px;
			right: 10px;
		}
	}

	&.bar-notification-dummy {
		.dummy-notification {
			padding: 20px 15px;
			cursor: pointer;
			background: #ddd;
			border-radius: 4px;
			height: 50px;
			position: absolute;

			.dummy-notification-content {
				height: 10px;
				width: 70%;
				background: #aaa;
				border-radius: 4px;
				position: relative;
			}

			.dummy-notification-close {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #aaa;
				position: absolute;
				top:10px;
				right: 10px;
			}
		}

		.dummy-top {
			top: 0;
			left: 10px;
			right: 10px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}

		.dummy-bottom {
			bottom: 0;
			left: 10px;
			right: 10px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}
	}


}

/* ====== Notification ===== 
------------------------------------------------------------ */

.notify-container {
	position: fixed;
	z-index: 990;
	
	// Notificaiton Wrapper
	.notify-wrapper {
		.notify-close-button {
			position: absolute;
			top: 10px;
			bottom: 0;
			right: 10px;
			cursor: pointer;
			backface-visibility: hidden;
			overflow: hidden;
		}
		

		// Thumb Circle Notification
		&[data-type="thumb-circle"] {
			width: 350px;
			margin-bottom: 15px;
			overflow: hidden;
			position: relative;
			border-radius: 50px;
			@include translate3d(0, 0, 0);


			&.notify-open,
			&.notify-close {
				.thumb-circle--container {
					.thumb-circle--image {
						@include animation(animJelly 1s linear forwards)
					}

					.thumb-circle--message {
						@include animation(animSlide 0.4s cubic-bezier(0.7,0,0.3,1) both);

						span {
							@include animation(animFade 0.3s forwards);
							opacity: 0;
						}
					}
				}

				.notify-close-button {
					@include animation(animFade 0.3s forwards);
					opacity: 0;
				}
			}

			&.notify-open {
				.thumb-circle--container {
					.thumb-circle--message {
						@include animation-delay(0.5s);

						span {
							@include animation-delay(0.8s);
						}
					}
				}

				.notify-close-button {
					@include animation-delay(0.8s);
				}
			}

			&.notify-close {
				.thumb-circle--container {
					.thumb-circle--image {
						@include animation-direction(reverse);
						@include animation-delay(0.3s);
					}

					.thumb-circle--message {
						@include animation-direction(reverse);
						@include animation-delay(0.3s);

						span {
							@include animation-direction(reverse);
						}
					}
				}

				.notify-close-button {
					@include animation-direction(reverse);
				}
			}

			.thumb-circle--container {
				position: relative;
				// padding: 2px 0;

				.thumb-circle--image {
					height: 65px;
					width: 65px;
					position: absolute;
					z-index: 9999;
					top: 0;
					bottom: 0;
					left: 0px;

					img {
						height: 100%;
						width: 100%;
						border-radius: 50%;
					}
				}

				.thumb-circle--message {
					height: 65px;
					line-height: 35px;
					background: $white;
					border-radius: 50px;
					padding: 15px 15px 15px 80px;
				}
			}
		}
		

		// Thumb Notification
		&[data-type="thumb"] {
			width: 350px;
			margin-bottom: 15px;
			overflow: hidden;
			position: relative;

			&.notify-open,
			&.notify-close {
				.thumb--container {
					.thumb--image {
						@include animation(animJelly 1s linear forwards)
					}

					.thumb--message {
						@include animation(animSlide 0.4s cubic-bezier(0.7,0,0.3,1) both);

						span {
							@include animation(animFade 0.3s forwards);
							opacity: 0;
						}
					}
				}

				.notify-close-button {
					@include animation(animFade 0.3s forwards);
					opacity: 0;
				}
			}

			&.notify-open {
				.thumb--container {
					.thumb--message {
						@include animation-delay(0.5s);

						span {
							@include animation-delay(0.8s);
						}
					}
				}

				.notify-close-button {
					@include animation-delay(0.8s);
				}
			}

			&.notify-close {
				.thumb--container {
					.thumb--image {
						@include animation-direction(reverse);
						@include animation-delay(0.3s);
					}

					.thumb--message {
						@include animation-direction(reverse);
						@include animation-delay(0.3s);

						span {
							@include animation-direction(reverse);
						}
					}
				}

				.notify-close-button {
					@include animation-direction(reverse);
				}
			}

			.thumb--container {
				position: relative;
				box-shadow: 0 7px 4px -3px rgba(0,0,0,0.3);

				.thumb--image {
					height: 65px;
					width: 65px;
					position: absolute;
					z-index: 100;
					overflow: hidden;
					// top: 0;
					// bottom: 0;
					// left: 0;

					img {
						height: 100%;
						width: 100%;
					}
				}

				.thumb--message {
					height: 65px;
					line-height: 35px;
					background: $white;
					padding: 15px 15px 15px 80px;
				}
			}
		}
		

		// Alert Notification
		&[data-type="alert"] {
			width: 350px;
			margin-bottom: 15px;
			position: relative;

			&[data-style="primary"] {
				.alert--container {
					background: lighten($primary, 35%);

					.alert--image,
					.alert--message {
						color: darken($primary, 15%);
					}
				}

				.notify-close-button {
					color: darken($primary, 15%);
				}
			}

			&[data-style="success"] {
				.alert--container {
					background: lighten($success, 45%);

					.alert--image,
					.alert--message {
						color: darken($success, 15%);
					}
				}

				.notify-close-button {
					color: darken($success, 15%);
				}
			}

			&[data-style="info"] {
				.alert--container {
					background: lighten($info, 45%);

					.alert--image,
					.alert--message {
						color: darken($info, 15%);
					}
				}

				.notify-close-button {
					color: darken($info, 15%);
				}
			}

			&[data-style="warning"] {
				.alert--container {
					background: lighten($warning, 40%);

					.alert--image,
					.alert--message {
						color: darken($warning, 15%);
					}
				}

				.notify-close-button {
					color: darken($warning, 15%);
				}
			}

			&[data-style="danger"] {
				.alert--container {
					background: lighten($danger, 36%);

					.alert--image,
					.alert--message {
						color: darken($danger, 15%);
					}
				}

				.notify-close-button {
					color: darken($danger, 15%);
				}
			}

			&.notify-open,
			&.notify-close {
				.alert--container {
					@include animation(animScale 0.4s ease);

					.alert--image,
					.alert--message {
						@include animation(animFade 0.3s forwards);
						opacity: 0;
					}
				}

				.notify-close-button {
					@include animation(animFade 0.3s forwards);
					opacity: 0;
				}
			}

			&.notify-open {
				.alert--container {
					.alert--image,
					.alert--message {
						@include animation-delay(0.3s);
					}
				}

				.notify-close-button {
					@include animation-delay(0.3s);
				}
			}

			&.notify-close {
				.alert--container {
					@include animation-direction(reverse);
					@include animation-delay(0.2s);

					.alert--image,
					.alert--message {
						@include animation-direction(reverse);
					}
				}

				.notify-close-button {
					@include animation-direction(reverse);
				}
			}

			.alert--container {
				position: relative;
				border-radius: 4px;
				box-shadow: 0 7px 4px -3px rgba(0,0,0,0.3);

				.alert--image {
					position: absolute;
					width: 65px;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					text-align: center;

					i {
						font-size: 36px;
						height: 100%;
						width: 100%;
						display: table;

						&:before {
							display: table-cell;
							vertical-align: middle;
						}
					}
				}

				.alert--message {
					padding: 25px 15px 25px 65px;
				}
			}
		}
		

		// Simple Notification
		&[data-type="simple"] {
			width: 350px;
			margin-bottom: 15px;
			position: relative;
			border-radius: 4px;

			&[data-style="primary"] {
				.simple--container {
					background: lighten($primary, 35%);

					.simple--message {
						color: darken($primary, 15%);
					}
				}

				.notify-close-button {
					color: darken($primary, 15%);
				}
			}

			&[data-style="success"] {
				.simple--container {
					background: lighten($success, 45%);

					.simple--message {
						color: darken($success, 15%);
					}
				}

				.notify-close-button {
					color: darken($success, 15%);
				}
			}

			&[data-style="info"] {
				.simple--container {
					background: lighten($info, 45%);

					.simple--message {
						color: darken($info, 15%);
					}
				}

				.notify-close-button {
					color: darken($info, 15%);
				}
			}

			&[data-style="warning"] {
				.simple--container {
					background: lighten($warning, 40%);

					.simple--message {
						color: darken($warning, 15%);
					}
				}

				.notify-close-button {
					color: darken($warning, 15%);
				}
			}

			&[data-style="danger"] {
				.simple--container {
					background: lighten($danger, 36%);

					.simple--message {
						color: darken($danger, 15%);
					}
				}

				.notify-close-button {
					color: darken($danger, 15%);
				}
			}

			&.notify-open,
			&.notify-close {
				.simple--container {
					@include animation(animScale 0.4s ease);

					.simple--image,
					.simple--message {
						@include animation(animFade 0.3s forwards);
						opacity: 0;
					}
				}

				.notify-close-button {
					@include animation(animFade 0.3s forwards);
					opacity: 0;
				}
			}

			&.notify-open {
				.simple--container {
					.simple--image,
					.simple--message {
						@include animation-delay(0.3s);
					}
				}

				.notify-close-button {
					@include animation-delay(0.3s);
				}
			}

			&.notify-close {
				.simple--container {
					@include animation-direction(reverse);
					@include animation-delay(0.2s);

					.simple--image,
					.simple--message {
						@include animation-direction(reverse);
					}
				}

				.notify-close-button {
					@include animation-direction(reverse);
				}
			}

			.simple--container {
				background: lighten($primary, 30%);
				color: $black;
				border-radius: 4px;
				box-shadow: 0 7px 4px -3px rgba(0,0,0,0.3);

				.simple--message {
					padding: 25px 15px;
				}
			}
		}
		

		// Flip Notificaiton
		&[data-type="flip"] {
			width: 350px;
			margin-bottom: 15px;
			position: relative;
			-webkit-transform-origin: 50% 0%;
			-ms-transform-origin: 50% 0%;
			-o-transform-origin: 50% 0%;
    		transform-origin: 50% 0%;

    		&[data-style="primary"] {
				.flip--container {
					background: lighten($primary, 35%);

					.flip--message {
						color: darken($primary, 15%);
					}
				}

				.notify-close-button {
					color: darken($primary, 15%);
				}
			}

			&[data-style="success"] {
				.flip--container {
					background: lighten($success, 45%);

					.flip--message {
						color: darken($success, 15%);
					}
				}

				.notify-close-button {
					color: darken($success, 15%);
				}
			}

			&[data-style="info"] {
				.flip--container {
					background: lighten($info, 45%);

					.flip--message {
						color: darken($info, 15%);
					}
				}

				.notify-close-button {
					color: darken($info, 15%);
				}
			}

			&[data-style="warning"] {
				.flip--container {
					background: lighten($warning, 40%);

					.flip--message {
						color: darken($warning, 15%);
					}
				}

				.notify-close-button {
					color: darken($warning, 15%);
				}
			}

			&[data-style="danger"] {
				.flip--container {
					background: lighten($danger, 36%);

					.flip--message {
						color: darken($danger, 15%);
					}
				}

				.notify-close-button {
					color: darken($danger, 15%);
				}
			}

    		&.notify-open {
    			-webkit-animation-name: flipInX;
    			-ms-animation-name: flipInX;
			    animation-name: flipInX;
			    -webkit-animation-duration: 0.8s;
			    -ms-animation-duration: 0.8s;
			    animation-duration: 0.8s;
    		}

    		&.notify-close {
    			-webkit-animation-name: flipInXSimple;
    			-ms-animation-name: flipInXSimple;
			    animation-name: flipInXSimple;
			    -webkit-animation-duration: 0.3s;
			    -ms-animation-duration: 0.3s;
			    animation-duration: 0.3s;
			    -webkit-animation-direction: reverse;
			    -ms-animation-direction: reverse;
			    animation-direction: reverse;
    		}

			.flip--container {
				box-shadow: 0 7px 4px -3px rgba(0,0,0,0.3);

				.flip--message {
					padding: 25px 15px;
				}
			}
		}
		

		// Bar Notification
		&[data-type="bar"] {
			width: 100%;
			position: relative;

			&.notify-open,
			&.notify-close {
				-webkit-animation-name: animSlideTop;
				animation-name: animSlideTop;
				-webkit-animation-duration: 0.3s;
				animation-duration: 0.3s;
			}

			&.notify-close {
				-webkit-animation-direction: reverse;
				animation-direction: reverse;
			}

			&[data-style="primary"] {
				.bar--container {
					background: lighten($primary, 35%);

					.bar--message {
						color: darken($primary, 15%);
					}
				}

				.notify-close-button {
					color: darken($primary, 15%);
				}
			}

			&[data-style="success"] {
				.bar--container {
					background: lighten($success, 45%);

					.bar--message {
						color: darken($success, 15%);
					}
				}

				.notify-close-button {
					color: darken($success, 15%);
				}
			}

			&[data-style="info"] {
				.bar--container {
					background: lighten($info, 45%);

					.bar--message {
						color: darken($info, 15%);
					}
				}

				.notify-close-button {
					color: darken($info, 15%);
				}
			}

			&[data-style="warning"] {
				.bar--container {
					background: lighten($warning, 40%);

					.bar--message {
						color: darken($warning, 15%);
					}
				}

				.notify-close-button {
					color: darken($warning, 15%);
				}
			}

			&[data-style="danger"] {
				.bar--container {
					background: lighten($danger, 36%);

					.bar--message {
						color: darken($danger, 15%);
					}
				}

				.notify-close-button {
					color: darken($danger, 15%);
				}
			}

			.bar--container {
				background: $info;
				box-shadow: 0 7px 4px -3px rgba(0,0,0,0.3);

				.bar--message {
					padding: 20px 15px;
				}
			}
		}
	}
	

	// Noticiation Position Top
	&[data-position="top"] {
		top: 50px;
		left: 225px;
		right: 0;
		overflow: hidden;
	}
	

	// Noticiation Position Bottom
	&[data-position="bottom"] {
		bottom: 0px;
		left: 225px;
		right: 0;
		overflow: hidden;
		
		[data-type="bar"] {
			&.notify-open,
			&.notify-close {
				-webkit-animation-name: animSlideBottom;
				animation-name: animSlideBottom;
				-webkit-animation-duration: 0.3s;
				animation-duration: 0.3s;
			}

			&.notify-close {
				-webkit-animation-direction: reverse;
				animation-direction: reverse;
			}
		}
	}
	

	// Noticiation Position Top Right
	&[data-position="top-right"] {
		top: 65px;
		right: 15px;

		[data-type="flip"] {
			top: -15px;
		}
	}
	

	// Noticiation Position Top Left
	&[data-position="top-left"] {
		top: 65px;
		left: 265px;

		[data-type="flip"] {
			top: -15px;
		}
	}
	

	// Noticiation Position Bottom Right
	&[data-position="bottom-right"] {
		bottom: 15px;
		right: 15px;

		[data-type="flip"] {
			bottom: -15px;
			margin-bottom: 0 !important;
			-webkit-transform-origin: 50% 100%;
    		transform-origin: 50% 100%;

			&.notify-open {
    			-webkit-animation-name: flipInXBottom;
			    animation-name: flipInXBottom;
			    -webkit-animation-duration: 0.8s;
			    animation-duration: 0.8s;
    		}

    		&.notify-close {
    			-webkit-animation-name: flipInXSimpleBottom;
			    animation-name: flipInXSimpleBottom;
			    -webkit-animation-duration: 0.3s;
			    animation-duration: 0.3s;
			    -webkit-animation-direction: reverse;
			    animation-direction: reverse;
    		}

    		.flip--container {
				box-shadow: 0px -7px 4px -4px rgba(0,0,0,0.3) !important;
    		}
		}
	}
	

	// Noticiation Position Bottom Left
	&[data-position="bottom-left"] {
		bottom: 15px;
		left: 265px;

		[data-type="flip"] {
			bottom: -15px;
			margin-bottom: 0 !important;
			-webkit-transform-origin: 50% 100%;
    		transform-origin: 50% 100%;

			&.notify-open {
    			-webkit-animation-name: flipInXBottom;
			    animation-name: flipInXBottom;
			    -webkit-animation-duration: 0.8s;
			    animation-duration: 0.8s;
    		}

    		&.notify-close {
    			-webkit-animation-name: flipInXSimpleBottom;
			    animation-name: flipInXSimpleBottom;
			    -webkit-animation-duration: 0.3s;
			    animation-duration: 0.3s;
			    -webkit-animation-direction: reverse;
			    animation-direction: reverse;
    		}

    		.flip--container {
				box-shadow: 0px -7px 4px -4px rgba(0,0,0,0.3) !important;
    		}
		}
	}
}


// Navbar Collapsed Notificatoin Positions
.navbar-collapsed {
	.notify-container {
		&[data-position="top-left"],
		&[data-position="bottom-left"] {
			left: 90px;
		}

		&[data-position="top"],
		&[data-position="bottom"] {
			left: 75px;
		}
	}
}

// Keyframes for Thumb
@-webkit-keyframes animJelly {
	0% { opacity: 0; -webkit-transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	2.083333% { -webkit-transform: matrix3d(0.75266, 0, 0, 0, 0, 0.76342, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.75266, 0, 0, 0, 0, 0.76342, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	4.166667% { -webkit-transform: matrix3d(0.81071, 0, 0, 0, 0, 0.84545, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.81071, 0, 0, 0, 0, 0.84545, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	6.25% { -webkit-transform: matrix3d(0.86808, 0, 0, 0, 0, 0.9286, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.86808, 0, 0, 0, 0, 0.9286, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	8.333333% { -webkit-transform: matrix3d(0.92038, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.92038, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	10.416667% { opacity: 1; -webkit-transform: matrix3d(0.96482, 0, 0, 0, 0, 1.05202, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.96482, 0, 0, 0, 0, 1.05202, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	12.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1.08204, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1.08204, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	14.583333% { -webkit-transform: matrix3d(1.02563, 0, 0, 0, 0, 1.09149, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.02563, 0, 0, 0, 0, 1.09149, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	16.666667% { -webkit-transform: matrix3d(1.04227, 0, 0, 0, 0, 1.08453, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.04227, 0, 0, 0, 0, 1.08453, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	18.75% { -webkit-transform: matrix3d(1.05102, 0, 0, 0, 0, 1.06666, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.05102, 0, 0, 0, 0, 1.06666, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	20.833333% { -webkit-transform: matrix3d(1.05334, 0, 0, 0, 0, 1.04355, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.05334, 0, 0, 0, 0, 1.04355, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	22.916667% { -webkit-transform: matrix3d(1.05078, 0, 0, 0, 0, 1.02012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.05078, 0, 0, 0, 0, 1.02012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	25% { -webkit-transform: matrix3d(1.04487, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.04487, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	27.083333% { -webkit-transform: matrix3d(1.03699, 0, 0, 0, 0, 0.98534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.03699, 0, 0, 0, 0, 0.98534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	29.166667% { -webkit-transform: matrix3d(1.02831, 0, 0, 0, 0, 0.97688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.02831, 0, 0, 0, 0, 0.97688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	31.25% { -webkit-transform: matrix3d(1.01973, 0, 0, 0, 0, 0.97422, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.01973, 0, 0, 0, 0, 0.97422, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	33.333333% { -webkit-transform: matrix3d(1.01191, 0, 0, 0, 0, 0.97618, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.01191, 0, 0, 0, 0, 0.97618, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	35.416667% { -webkit-transform: matrix3d(1.00526, 0, 0, 0, 0, 0.98122, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00526, 0, 0, 0, 0, 0.98122, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	37.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 0.98773, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 0.98773, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	39.583333% { -webkit-transform: matrix3d(0.99617, 0, 0, 0, 0, 0.99433, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99617, 0, 0, 0, 0, 0.99433, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	41.666667% { -webkit-transform: matrix3d(0.99368, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99368, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	43.75% { -webkit-transform: matrix3d(0.99237, 0, 0, 0, 0, 1.00413, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99237, 0, 0, 0, 0, 1.00413, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	45.833333% { -webkit-transform: matrix3d(0.99202, 0, 0, 0, 0, 1.00651, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99202, 0, 0, 0, 0, 1.00651, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	47.916667% { -webkit-transform: matrix3d(0.99241, 0, 0, 0, 0, 1.00726, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99241, 0, 0, 0, 0, 1.00726, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	50% { -webkit-transform: matrix3d(0.99329, 0, 0, 0, 0, 1.00671, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99329, 0, 0, 0, 0, 1.00671, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	52.083333% { -webkit-transform: matrix3d(0.99447, 0, 0, 0, 0, 1.00529, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99447, 0, 0, 0, 0, 1.00529, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	54.166667% { -webkit-transform: matrix3d(0.99577, 0, 0, 0, 0, 1.00346, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99577, 0, 0, 0, 0, 1.00346, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	56.25% { -webkit-transform: matrix3d(0.99705, 0, 0, 0, 0, 1.0016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99705, 0, 0, 0, 0, 1.0016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	58.333333% { -webkit-transform: matrix3d(0.99822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	60.416667% { -webkit-transform: matrix3d(0.99921, 0, 0, 0, 0, 0.99884, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99921, 0, 0, 0, 0, 0.99884, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	62.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 0.99816, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 0.99816, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	64.583333% { -webkit-transform: matrix3d(1.00057, 0, 0, 0, 0, 0.99795, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00057, 0, 0, 0, 0, 0.99795, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	66.666667% { -webkit-transform: matrix3d(1.00095, 0, 0, 0, 0, 0.99811, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00095, 0, 0, 0, 0, 0.99811, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	68.75% { -webkit-transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99851, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99851, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	70.833333% { -webkit-transform: matrix3d(1.00119, 0, 0, 0, 0, 0.99903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00119, 0, 0, 0, 0, 0.99903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	72.916667% { -webkit-transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99955, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99955, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	75% { -webkit-transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	77.083333% { -webkit-transform: matrix3d(1.00083, 0, 0, 0, 0, 1.00033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00083, 0, 0, 0, 0, 1.00033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	79.166667% { -webkit-transform: matrix3d(1.00063, 0, 0, 0, 0, 1.00052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00063, 0, 0, 0, 0, 1.00052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	81.25% { -webkit-transform: matrix3d(1.00044, 0, 0, 0, 0, 1.00058, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00044, 0, 0, 0, 0, 1.00058, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	83.333333% { -webkit-transform: matrix3d(1.00027, 0, 0, 0, 0, 1.00053, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00027, 0, 0, 0, 0, 1.00053, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	85.416667% { -webkit-transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	87.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1.00027, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1.00027, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	89.583333% { -webkit-transform: matrix3d(0.99991, 0, 0, 0, 0, 1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99991, 0, 0, 0, 0, 1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	91.666667% { -webkit-transform: matrix3d(0.99986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	93.75% { -webkit-transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	95.833333% { -webkit-transform: matrix3d(0.99982, 0, 0, 0, 0, 0.99985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99982, 0, 0, 0, 0, 0.99985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	97.916667% { -webkit-transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}

@keyframes animJelly {
	0% { opacity: 0; -webkit-transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	2.083333% { -webkit-transform: matrix3d(0.75266, 0, 0, 0, 0, 0.76342, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.75266, 0, 0, 0, 0, 0.76342, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	4.166667% { -webkit-transform: matrix3d(0.81071, 0, 0, 0, 0, 0.84545, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.81071, 0, 0, 0, 0, 0.84545, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	6.25% { -webkit-transform: matrix3d(0.86808, 0, 0, 0, 0, 0.9286, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.86808, 0, 0, 0, 0, 0.9286, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	8.333333% { -webkit-transform: matrix3d(0.92038, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.92038, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	10.416667% { opacity: 1; -webkit-transform: matrix3d(0.96482, 0, 0, 0, 0, 1.05202, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.96482, 0, 0, 0, 0, 1.05202, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	12.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1.08204, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1.08204, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	14.583333% { -webkit-transform: matrix3d(1.02563, 0, 0, 0, 0, 1.09149, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.02563, 0, 0, 0, 0, 1.09149, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	16.666667% { -webkit-transform: matrix3d(1.04227, 0, 0, 0, 0, 1.08453, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.04227, 0, 0, 0, 0, 1.08453, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	18.75% { -webkit-transform: matrix3d(1.05102, 0, 0, 0, 0, 1.06666, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.05102, 0, 0, 0, 0, 1.06666, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	20.833333% { -webkit-transform: matrix3d(1.05334, 0, 0, 0, 0, 1.04355, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.05334, 0, 0, 0, 0, 1.04355, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	22.916667% { -webkit-transform: matrix3d(1.05078, 0, 0, 0, 0, 1.02012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.05078, 0, 0, 0, 0, 1.02012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	25% { -webkit-transform: matrix3d(1.04487, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.04487, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	27.083333% { -webkit-transform: matrix3d(1.03699, 0, 0, 0, 0, 0.98534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.03699, 0, 0, 0, 0, 0.98534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	29.166667% { -webkit-transform: matrix3d(1.02831, 0, 0, 0, 0, 0.97688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.02831, 0, 0, 0, 0, 0.97688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	31.25% { -webkit-transform: matrix3d(1.01973, 0, 0, 0, 0, 0.97422, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.01973, 0, 0, 0, 0, 0.97422, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	33.333333% { -webkit-transform: matrix3d(1.01191, 0, 0, 0, 0, 0.97618, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.01191, 0, 0, 0, 0, 0.97618, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	35.416667% { -webkit-transform: matrix3d(1.00526, 0, 0, 0, 0, 0.98122, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00526, 0, 0, 0, 0, 0.98122, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	37.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 0.98773, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 0.98773, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	39.583333% { -webkit-transform: matrix3d(0.99617, 0, 0, 0, 0, 0.99433, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99617, 0, 0, 0, 0, 0.99433, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	41.666667% { -webkit-transform: matrix3d(0.99368, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99368, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	43.75% { -webkit-transform: matrix3d(0.99237, 0, 0, 0, 0, 1.00413, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99237, 0, 0, 0, 0, 1.00413, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	45.833333% { -webkit-transform: matrix3d(0.99202, 0, 0, 0, 0, 1.00651, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99202, 0, 0, 0, 0, 1.00651, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	47.916667% { -webkit-transform: matrix3d(0.99241, 0, 0, 0, 0, 1.00726, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99241, 0, 0, 0, 0, 1.00726, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	50% { -webkit-transform: matrix3d(0.99329, 0, 0, 0, 0, 1.00671, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99329, 0, 0, 0, 0, 1.00671, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	52.083333% { -webkit-transform: matrix3d(0.99447, 0, 0, 0, 0, 1.00529, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99447, 0, 0, 0, 0, 1.00529, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	54.166667% { -webkit-transform: matrix3d(0.99577, 0, 0, 0, 0, 1.00346, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99577, 0, 0, 0, 0, 1.00346, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	56.25% { -webkit-transform: matrix3d(0.99705, 0, 0, 0, 0, 1.0016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99705, 0, 0, 0, 0, 1.0016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	58.333333% { -webkit-transform: matrix3d(0.99822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	60.416667% { -webkit-transform: matrix3d(0.99921, 0, 0, 0, 0, 0.99884, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99921, 0, 0, 0, 0, 0.99884, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	62.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 0.99816, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 0.99816, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	64.583333% { -webkit-transform: matrix3d(1.00057, 0, 0, 0, 0, 0.99795, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00057, 0, 0, 0, 0, 0.99795, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	66.666667% { -webkit-transform: matrix3d(1.00095, 0, 0, 0, 0, 0.99811, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00095, 0, 0, 0, 0, 0.99811, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	68.75% { -webkit-transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99851, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99851, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	70.833333% { -webkit-transform: matrix3d(1.00119, 0, 0, 0, 0, 0.99903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00119, 0, 0, 0, 0, 0.99903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	72.916667% { -webkit-transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99955, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99955, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	75% { -webkit-transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	77.083333% { -webkit-transform: matrix3d(1.00083, 0, 0, 0, 0, 1.00033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00083, 0, 0, 0, 0, 1.00033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	79.166667% { -webkit-transform: matrix3d(1.00063, 0, 0, 0, 0, 1.00052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00063, 0, 0, 0, 0, 1.00052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	81.25% { -webkit-transform: matrix3d(1.00044, 0, 0, 0, 0, 1.00058, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00044, 0, 0, 0, 0, 1.00058, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	83.333333% { -webkit-transform: matrix3d(1.00027, 0, 0, 0, 0, 1.00053, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00027, 0, 0, 0, 0, 1.00053, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	85.416667% { -webkit-transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	87.5% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1.00027, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1.00027, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	89.583333% { -webkit-transform: matrix3d(0.99991, 0, 0, 0, 0, 1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99991, 0, 0, 0, 0, 1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	91.666667% { -webkit-transform: matrix3d(0.99986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	93.75% { -webkit-transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	95.833333% { -webkit-transform: matrix3d(0.99982, 0, 0, 0, 0, 0.99985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99982, 0, 0, 0, 0, 0.99985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	97.916667% { -webkit-transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
	100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}



// Keyframes for Thumb
@-webkit-keyframes animSlide {
	0% { -webkit-transform: translate3d(-100%,0,0); }
	100% { -webkit-transform: translate3d(0,0,0);}
}


// Keyframes for Thumb
@keyframes animSlide {
	0% { -webkit-transform: translate3d(-100%,0,0); transform: translate3d(-100%,0,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

@-webkit-keyframes animFade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes animFade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@-webkit-keyframes animScale {
	0% { opacity: 0; -webkit-transform: translate3d(0,40px,0) scale3d(0.1,0.6,1); }
	100% { opacity: 1; -webkit-transform: translate3d(0,0,0) scale3d(1,1,1); }
}

@keyframes animScale {
	0% { opacity: 0; -webkit-transform: translate3d(0,40px,0) scale3d(0.1,0.6,1); transform: translate3d(0,40px,0) scale3d(0.1,0.6,1); }
	100% { opacity: 1; -webkit-transform: translate3d(0,0,0) scale3d(1,1,1); transform: translate3d(0,0,0) scale3d(1,1,1); }
}


@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -webkit-transition-timing-function: ease-in;
        -ms-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,20deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,20deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,20deg);
        transform: perspective(400px) rotate3d(1,0,0,20deg);
        -webkit-transition-timing-function: ease-out;
        -ms-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -webkit-transition-timing-function: ease-in;
        -ms-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,5deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,5deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,5deg);
        transform: perspective(400px) rotate3d(1,0,0,5deg);
        -webkit-transition-timing-function: ease-out;
        -ms-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    100% {
        -webkit-transform: perspective(400px);
        -ms-transform: perspective(400px);
        -o-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -webkit-transition-timing-function: ease-in;
        -ms-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,20deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,20deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,20deg);
        transform: perspective(400px) rotate3d(1,0,0,20deg);
        -webkit-transition-timing-function: ease-out;
        -ms-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -webkit-transition-timing-function: ease-in;
        -ms-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,5deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,5deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,5deg);
        transform: perspective(400px) rotate3d(1,0,0,5deg);
        -webkit-transition-timing-function: ease-out;
        -ms-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    100% {
        -webkit-transform: perspective(400px);
        -ms-transform: perspective(400px);
        -o-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-webkit-keyframes flipInXSimple {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -o-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    -ms-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    -o-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInXSimple {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -o-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    -ms-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    -o-transform: perspective(400px);
    transform: perspective(400px);
  }
}



@-webkit-keyframes flipInXBottom {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-0deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-0deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-0deg);
        transform: perspective(400px) rotate3d(1,0,0,-0deg);
        -webkit-transition-timing-function: ease-in;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-20deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-20deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-20deg);
        transform: perspective(400px) rotate3d(1,0,0,-20deg);
        -webkit-transition-timing-function: ease-out;
        -ms-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,10deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,10deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,10deg);
        transform: perspective(400px) rotate3d(1,0,0,10deg);
        -webkit-transition-timing-function: ease-in;
        -ms-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-5deg);
        -ms-transform: perspective(400px) rotate3d(1,0,0,-5deg);
        -o-transform: perspective(400px) rotate3d(1,0,0,-5deg);
        transform: perspective(400px) rotate3d(1,0,0,-5deg);
        -webkit-transition-timing-function: ease-out;
        -ms-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    100% {
        -webkit-transform: perspective(400px);
        -ms-transform: perspective(400px);
        -o-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInXBottom {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,90deg);
        transform: perspective(400px) rotate3d(1,0,0,90deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-20deg);
        transform: perspective(400px) rotate3d(1,0,0,-20deg);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,10deg);
        transform: perspective(400px) rotate3d(1,0,0,10deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-5deg);
        transform: perspective(400px) rotate3d(1,0,0,-5deg);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@-webkit-keyframes flipInXSimpleBottom {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}

@keyframes flipInXSimpleBottom {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}





@-webkit-keyframes animSlideTop {
	0% { -webkit-transform: translate3d(0,-100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideTop {
	0% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

@-webkit-keyframes animSlideBottom {
	0% { -webkit-transform: translate3d(0,100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideBottom {
	0% { -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}