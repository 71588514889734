/* ----------------------------------------------------
 * 24. Calendar
 *  
 * Contains all the styling for jQuery fullCalendar
 * ---------------------------------------------------- */
 
 // Main Container
.fc.fc-ltr {
	color: #555;
	

	// Calendar header
	.fc-header-toolbar {
		margin: 0;
		position: relative;

		.fc-left {
			.fc-prev-button,
			.fc-next-button {
				position: absolute;
				top: 40px;
				height: 60px;
				line-height: 60px;
				background: transparent;
				border: none;
				box-shadow: none;
			}

			.fc-prev-button {
				left: 0;
			}

			.fc-next-button {
				right: 0;
			}
		}

		.fc-center {
			width: 100%;
			height: 60px;
			margin-top: 15px;
			background: $white;
			border: solid 1px #ddd;

			h2 {
				display: block;
				line-height: 60px;
				text-align: center;
				float: none;
			}
		}

		.fc-right {
			button {
				background: $primary;
				box-shadow: none;
				color: $white;
				text-shadow: none;
				// width: 75px;
				border: none;
				text-transform: uppercase;

				&.fc-state-active {
					background: lighten($primary, 5%);
				}
			}
		}
	}
	

	// Calendar View Container
	.fc-view-container {
		background: $white;
		

		// Calendar view
		.fc-view {
			table {
				thead.fc-head {
					th {
						height: 25px;
						line-height: 25px;
						text-transform: uppercase;
						background: #fff;
						border: none;
						font-family: $header-font-family;
					}
				}
				tbody.fc-body {
					.fc-day {
						border: solid 1px #f1f1f1;
					}
					
					.fc-event-container {
						.fc-event {
							background: #B387DE;
							border: none;
						}
					}
				}
			}
		}
	}
}